<template>
    <v-snackbar :color="color" v-model="open">
        <span v-html="message"></span>
    </v-snackbar>
</template>

<script>
    export default {
        name: "Snackbar",
        data: () => ({
            open: false,
            message: String,
            color: ''
        }),
        methods: {
            show(message, color = 'red') {
                this.message = message;
                this.color = color;
                this.open = true;
            }
        }
    }
</script>
