import Vue from 'vue'
import Router from 'vue-router'
import Pharma from "./views/pharma/Pharma";
import PharmaForm from "./views/pharma/PharmaForm";
import PharmaDetail from "./views/pharma/PharmaDetail";
import Login from "./views/Login";
import AuthService from "./service/AuthService";
import InstituteDetail from "@/views/institute/InstituteDetail";
import InstituteForm from "@/views/institute/InstituteForm";
import Institute from "@/views/institute/Institute";
import User from "./views/user/User";
import Home from "./views/Home";
import UserDetail from "./views/user/UserDetail";
import UserForm from "./views/user/UserForm";
import ClinicalStudy from "./views/clinical-study/ClinicalStudy";
import ClinicalStudyForm from "./views/clinical-study/ClinicalStudyForm";
import ClinicalStudyDetail from "./views/clinical-study/ClinicalStudyDetail";
import RecoverPassword from "@/views/RecoverPassword";
import ChangePassword from "@/views/ChangePassword";
import RecoverPasswordConfirmed from "@/views/RecoverPasswordConfirmed";
import PasswordChanged from "@/views/PasswordChanged";
import NetworkDetail from "./views/network/NetworkDetail";
import NetworkSearch from "./views/network/NetworkSearch";
import Network from "./views/network/Network";
import Taxonomy from "@/views/taxonomy/Taxonomy";
import TaxonomyForm from "@/views/taxonomy/TaxonomyForm";
import TaxonomyDetail from "@/views/taxonomy/TaxonomyDetail";


window._ = require('lodash');

Vue.use(Router);

let authService = new AuthService();

const ifNotAuthenticated = (_to, _from, next) => {
    if (!authService.isAuthenticated()) {
        next();
        return
    }
    next('/')
};

const ifAuthenticated = (_to, _from, next) => {
    if (authService.isAuthenticated()) {
        next();
        return
    }
    next('/login')
};

export default new Router({
    routes: [
        {
            path: '/',
            component: Home,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/recover-password',
            component: RecoverPassword,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/change-password/:token',
            component: ChangePassword,
            beforeEnter: ifNotAuthenticated,
            props: {type: 'change'}
        },
        {
            path: '/recover-password-confirmed',
            component: RecoverPasswordConfirmed,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/password-changed',
            component: PasswordChanged,
            beforeEnter: ifNotAuthenticated,
            props: {message: 'Password reimpostata.'}
        },
        {
            path: '/update-password',
            component: ChangePassword,
            beforeEnter: ifAuthenticated,
            props: {type: 'password'}
        },
        {
            path: '/first-access/:token',
            component: ChangePassword,
            beforeEnter: ifNotAuthenticated,
            props: {type: 'token'}
        },
        {
            path: '/first-access-password-changed',
            component: PasswordChanged,
            beforeEnter: ifNotAuthenticated,
            props: {message: 'Password impostata.'}
        },
        {
            path: '/pharma',
            component: Pharma,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/pharma/new',
            component: PharmaForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/pharma/detail/:id',
            component: PharmaDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/pharma/edit/:id',
            component: PharmaForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute',
            component: Institute,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute/new',
            component: InstituteForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute/detail/:id',
            component: InstituteDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute/edit/:id',
            component: InstituteForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/user',
            component: User,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/user/detail/:id',
            component: UserDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/user/edit/:id',
            component: UserForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/user/new',
            component: UserForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/clinical-study',
            component: ClinicalStudy,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/clinical-study/detail/:id',
            component: ClinicalStudyDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/clinical-study/edit/:id',
            component: ClinicalStudyForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/clinical-study/new',
            component: ClinicalStudyForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network',
            component: Network,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/new',
            component: NetworkSearch,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/add',
            component: InstituteForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/detail/:id',
            component: NetworkDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute-owner/:id',
            component: NetworkDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/edit/:id',
            component: InstituteForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/user/new/:institute',
            component: UserForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/user/edit/:id/:institute',
            component: UserForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/user/detail/:id',
            component: UserDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/taxonomy',
            component: Taxonomy,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/taxonomy/edit/:id',
            component: TaxonomyForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/taxonomy/detail/:id',
            component: TaxonomyDetail,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/taxonomy/new',
            component: TaxonomyForm,
            beforeEnter: ifAuthenticated
        },
		{
            path: '/institute-owner/:institute/user/new',
            component: UserForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute-owner/:institute/user/edit/:id',
            component: UserForm,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/institute-owner/:institute/user/detail/:id',
            component: UserDetail,
            beforeEnter: ifAuthenticated
        }
    ]
})
