<template>
    <div class="slide-panel-wrap" :class="{ open: opened }">
        <div class="slide-panel pa-12">
            <div class="d-flex align-content-center justify-space-between mb-4">
                <h2>{{title}}</h2>
                <v-icon @click="close()">fa-solid fa-times</v-icon>
            </div>
            <span class="font-weight-light">
                {{message}}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SlidePanel",
        data: () => ({
            title: null,
            message: null,
            opened: false
        }),
        methods: {
            open(title, message) {
                this.title = title;
                this.message = message;
                this.opened = true;
            },

            close() {
                this.opened = false;
            }
        }
    }
</script>
