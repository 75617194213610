<template>
    <v-btn class="mx-2" small @click="deleteElement($event)" color="error" :title="title">
        <v-icon small :left="text">fa-solid fa-trash</v-icon>
        {{ text }}
    </v-btn>
</template>

<script>
export default {
    name: "DeleteButton",
    model: {
        event: 'delete'
    },
    props: [
        'text',
        'title'
    ],
    methods: {
        deleteElement(event) {
            this.$root.showConfirm('Elimina elemento', 'Sei sicuro di voler eliminare questo elemento?', this, 'delete');
            event.preventDefault();
        }
    }
}
</script>
