import axios from "axios";

export default class BaseService {

    constructor(name) {
        this.name = name;
    }

    search(params) {
        return axios.get(`api/${this.name}${this.paramsToQueryString(params)}`);
    }

    findAll() {
        return axios.get(`api/${this.name}/all`);
    }

    find(id) {
        return axios.get(`api/${this.name}/${id}`)
    }

    export(params) {
        return axios.get(`api/${this.name}/export${this.paramsToQueryString(params)}`)
    }

    save(entity) {
        if (entity.id) {
            return axios.put(`api/${this.name}/${entity.id}`, entity);
        } else {
            return axios.post(`api/${this.name}`, entity);
        }
    }

    delete(id) {
        return axios.delete(`api/${this.name}/${id}`)
    }

    paramsToQueryString(params) {
        let queryString = '';
        if (params) {
            queryString = Object.keys(params).map(function (key) {
                return key + '=' + params[key]
            }).join('&');

            queryString = '?' + queryString;
        }
        return queryString;
    }

    mapForView(entity) {
        return entity;
    }
}
