<template>
    <page-detail :config="config" :model.sync="pharma"></page-detail>
</template>

<script>

import PharmaService from "@/service/PharmaService";
import PageDetail from "@/components/PageDetail";

export default {
    name: 'PharmaDetail',
    components: {PageDetail},
    data() {
        return {
            pharma: {},
            config: {
                text: {
                    title: 'Pharma - Dettaglio',
                    name: 'Pharma'
                },
                service: new PharmaService(),
                fields: [
                    {
                        label: 'Ragione Sociale',
                        key: 'name',
                        cols: 4
                    },
                    {
                        label: 'Partita Iva',
                        key: 'vat_number',
                        cols: 4
                    },
                    {
                        label: 'Indirizzo',
                        key: 'address',
                        cols: 4
                    },
                    {
                        label: 'Provincia',
                        key: 'province',
                        cols: 4
                    },
                    {
                        label: 'Comune',
                        key: 'city',
                        cols: 4
                    },
                    {
                        label: 'Licenza',
                        key: 'license.name',
                        cols: 4
                    },
                    {
                        label: 'Scadenza Licenza',
                        key: 'expirationDate',
                        cols: 4
                    },
                    {
                        label: 'Hub disponibili',
                        key: 'size',
                        cols: 4
                    }
                ]
            }
        }
    }
}

</script>
