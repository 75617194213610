import BaseService from "./BaseService";
import axios from "axios";

export default class InstituteService extends BaseService {

    constructor() {
        super('institute');
    }

    checkName(instituteId, name) {
        return axios.get(`api/institute/check-name/${instituteId}/${name}`)
    }
}
