<template>
    <div>
        <page :title="authService.hasAnyRole(['pharma']) ? 'Invita Centro Hub' : 'Invita Centro'" hide-divider="true" class="page-list">
            <template v-slot:header>
                <back-to-list></back-to-list>
                <search :placeholder="'Cerca un centro da invitare oppure aggiungi un nuovo centro'" v-model="word" class="ml-8 mr-8 search-institute-field"></search>
                <new-button :to="'/network/add'" :text="'Aggiungi un centro'"></new-button>
            </template>
            <template v-slot:content>
                <data-list class="network-invitation-list" :config="{
                                        items: institutes,
                                        loading: loading,
                                        pagination: true,
                                        page: options.page,
                                        size: options.itemsPerPage,
                                        total: total}" @changePage="changePage($event)">
                    <template v-slot:title="{item}">
                        {{item.name}} - {{item.province.value}}
                    </template>
                    <template v-slot:subtitle="{item}">
                        {{item.manager ? item.managerName : '-'}} {{item.manager ? ' - ' + formatUtil.fromArrayToString(item.manager.therapeuticAreas.map(t => t.value)) : ''}}
                    </template>
                    <template v-slot:action="{item}">
                        <v-btn v-if="!!item.manager" class="mx-2" color="primary" small @click="invite(item)" title="Invita">
                            <v-icon small>fa-solid fa-user-plus</v-icon>
                        </v-btn>
                    </template>
                </data-list>
            </template>
        </page>
        <dialog-content :options="{btnType: 'primary', confirmText: 'Invita'}" ref="confirmInvitation"></dialog-content>
    </div>
</template>

<script>
import BackToList from "@/components/BackToList";
import Page from "../../components/Page";
import Search from "../../components/Search";
import NewButton from "../../components/NewButton";
import DataList from "../../components/DataList";
import RequestUtil from "../../utils/RequestUtil";
import NetworkService from "../../service/NetworkService";
import FormatUtil from "../../utils/FormatUtil";
import DialogContent from "../../components/DialogContent";
import AuthService from "../../service/AuthService";

const requestUtil = new RequestUtil();
const formatUtil = new FormatUtil();
const networkService = new NetworkService();
const authService = new AuthService();

export default {
    name: "PageDetail",
    components: {Page, BackToList, Search, NewButton, DataList, DialogContent},
    data() {
        return {
            authService,
            word: null,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 20
            },
            total: null,
            institutes: [],
            formatUtil: formatUtil
        }
    },

    watch: {
        options: {
            handler() {
                if (!this.loading) {
                    this.load();
                }
            },
            deep: true
        },
        word: {
            handler() {
                if (!this.loading) {
                    this.load();
                }
            }
        }
    },

    methods: {

        load() {

            this.loading = true;
            networkService.searchForInvitation(requestUtil.buildParams(this.word, this.options)).then(result => {
                this.institutes = result.data.data;
                this.total = result.data.total;
                this.loading = false;
            });
        },

        changePage(page) {
            this.options.page = page;
        },

        invite(institute) {

            this.$refs.confirmInvitation.open('Conferma invito', 'Sarà inviato un messaggio al referente del centro...').then(() => {
                networkService.invite(institute.id).then(() => {
                    this.$root.$invitationSuccess.open('Centro Aggiunto').then(() => {
                        this.$router.push('/network');
                    });
                });
            });
        }
    }
}
</script>
