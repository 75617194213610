<template>
    <div class="data-list-container">
        <div class="d-flex align-center mb-4" v-if="$slots.header">
            <slot name="header"></slot>
        </div>

        <v-progress-linear v-if="config.loading" indeterminate></v-progress-linear>
        <span class="data-list-loader-placeholder" v-if="!config.loading"></span>

        <v-list :class="'data-list ' + (config.showBorder ? 'data-list-bordered' : '')">
            <template v-for="(item, i) of config.items">
                <v-divider v-if="i !== 0 && !config.showBorder" :key="'divider' + i"></v-divider>
                <v-list-item :link="config.link" :key="i" :to="config.linkPrefix + item[config.idField ? config.idField : 'id']">
                    <v-list-item-icon v-if="$scopedSlots.icon">
                        <slot name="icon" :item="item"></slot>
                    </v-list-item-icon>
                    <slot name="prepend-content" :item="item"></slot>
                    <v-list-item-content>
                        <v-list-item-title v-if="$scopedSlots.title">
                            <slot name="title" :item="item"></slot>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="$scopedSlots.subtitle">
                            <slot name="subtitle" :item="item"></slot>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <slot name="append-content" :item="item"></slot>
                    <v-list-item-action v-if="$scopedSlots.action">
                        <slot name="action" :item="item"></slot>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="i === config.items.length - 1 && config.showBorder" :key="'divider' + i"></v-divider>
            </template>
        </v-list>

        <v-pagination v-if="config.pagination && config.items && config.items.length > 0 && config.total > config.size" @input="changePage($event)" v-model="config.page" :length="Math.ceil(config.total / config.size)"></v-pagination>
    </div>
</template>

<script>

export default {
    name: "DataList",
    props: [
        'config'
    ],
    methods: {
        changePage(page) {
            this.$emit('changePage', page);
        }
    }
}
</script>

