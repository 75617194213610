<template>
    <page :title="getTitle()" class="page-detail page-detail-network">
        <template v-slot:header>
            <back-to-list title="Network"></back-to-list>
            <v-btn :to="'/institute/edit/' + institute.id" class="ml-5" color="primary" v-if="!progress && canEdit()">
                <v-icon left>fa-solid fa-pencil</v-icon>
                Modifica
            </v-btn>
            <v-btn class="ml-5" color="primary" @click="removeFromNetwork()" v-if="!isOwner()">
                <v-icon left>fa-solid fa-xmark</v-icon>
                Rimuovi da network
            </v-btn>
        </template>
        <template v-slot:content>
            <v-row>
                <v-col cols="12" md="5">
                    <detail-custom ref="userDetail" :key="'userDetail'" :model="institute" multiFieldXRow="true" class="ma-4" :fields="fields"></detail-custom>
                </v-col>
                <v-col cols="0" md="0" class="mt-3">
                    <v-divider class="hidden-sm-and-down" vertical></v-divider>
                </v-col>
                <v-col cols="12" md="7" class="pl-0 pr-0">
                    <v-card elevation="0" class="">
                        <v-tabs v-model="tab" grow @change="selectTab" v-if="!isOwner()">
                            <v-tab key="study">
                                <v-icon left>
                                    fa-solid fa-flask
                                </v-icon>
                                Studi Clinici
                            </v-tab>
                            <v-tab key="network" v-if="!isOwner()">
                                <v-icon left>
                                    fa-solid fa-diagram-project
                                </v-icon>
                                Network
                            </v-tab>
                            <v-tab key="user">
                                <v-icon left>
                                    fa-solid fa-user
                                </v-icon>
                                Medici
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="study" transition="none" v-if="!isOwner()">
                                <data-list class="pa-4" :config="{idField: 'clinicalStudyId', items: institute.instituteClinicalStudies, link: true, linkPrefix: '/clinical-study/detail/'}">
                                    <template v-slot:icon="{item}">
                                        <v-icon v-if="item.study.status.key === 'closed'">fa-solid fa-lock</v-icon>
                                        <v-icon v-if="item.study.status.key === 'open' && item.study.pharma != null">fa-solid fa-pills</v-icon>
                                        <v-icon v-if="item.study.status.key === 'open' && item.study.pharma == null">fa-solid fa-stethoscope</v-icon>
                                    </template>
                                    <template v-slot:title="{item}">
                                        {{item.study.name}}
                                    </template>
                                </data-list>
                            </v-tab-item>
                            <v-tab-item key="network" transition="none" v-if="!isOwner()">
                                <data-list class="pa-4" :config="{items: institute.hubSpokes}">
                                    <template v-slot:title="{item}">
                                        {{item.spoke.manager ? item.spoke.manager.lastName + ' ' + item.spoke.manager.firstName : '-'}}
                                    </template>
                                    <template v-slot:subtitle="{item}">
                                        {{item.spoke.name}} {{item.spoke.province.value}}
                                    </template>
                                </data-list>
                            </v-tab-item>
                            <v-tab-item key="user" transition="none" class="pa-4">
                                <data-list :config="{
                                    items: user.content,
                                    loading: user.loading,
                                    pagination: true,
                                    page: userOptions.page,
                                    size: userOptions.itemsPerPage,
                                    total: user.total,
                                    link: true,
                                    linkPrefix: getUserLinkPrefix()}" @changePage="changePage($event)">
                                    <template v-slot:header>
                                        <new-button v-if="canAddUser()" :to="getAddUserTo()" title="Aggiungi Medico"></new-button>
                                        <search v-model="userWord" class="ml-4"></search>
                                    </template>
                                    <template v-slot:icon="{item}">
                                        <v-icon title="Referente" v-if="item.roles[0].code === 'manager'">fa-solid fa-user-tie</v-icon>
                                        <v-icon title="Medico" v-if="item.roles[0].code === 'user'">fa-solid fa-user-doctor</v-icon>
                                    </template>
                                    <template v-slot:title="{item}">
                                        {{item.fullNameAndTherapeuticAreasText}}
                                    </template>
                                    <template v-slot:subtitle="{item}">
                                        {{item.username}}
                                    </template>
                                    <template v-slot:action="{item}">
                                        <edit-button v-if="canEditUser(item)" title="Modifica" :to="getEditUserTo(item)"></edit-button>
                                        <delete-button v-if="canDeleteUser(item)" title="Elimina" @delete="confirmDelete(item)"></delete-button>
                                    </template>
                                </data-list>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </page>
</template>

<script>
import BackToList from "@/components/BackToList";
import Page from "@/components/Page";
import DetailCustom from "@/components/DetailCustom";
import NetworkService from "../../service/NetworkService";
import NewButton from "../../components/NewButton";
import Search from "../../components/Search";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import RequestUtil from "../../utils/RequestUtil";
import UserService from "../../service/UserService";
import DataList from "../../components/DataList";
import AuthService from "../../service/AuthService";

let networkService = new NetworkService();
const userService = new UserService();
const authService = new AuthService();
const requestUtil = new RequestUtil();

export default {
    name: "NetworkDetail",
    components: {DataList, BackToList, Page, DetailCustom, NewButton, Search, EditButton, DeleteButton},
    data() {
        return {
            authService,
            user: {
                headers: [
                    {text: 'Username', value: 'username', width: '15%'},
                    {text: 'Cognome', value: 'lastName', width: '15%'},
                    {text: 'Nome', value: 'firstName', width: '15%'},
                    {text: 'Ruolo', value: 'role', width: '20%', sortable: false},
                    {text: '', value: 'action', sortable: false, width: '20%'},
                ],
                total: 0,
                loading: false,
                content: [],
            },
            userOptions: {
                page: 1
            },
            userWord: null,
            tab: null,
            progress: true,
            institute: {},
            fields: [
                {
                    label: 'Centro',
                    key: 'name',
                    cols: 12
                },
                {
                    label: 'Studi attivi',
                    key: 'number',
                    cols: 4
                },
                {
                    label: 'Network',
                    key: 'network',
                    cols: 4
                },
                {
                    label: 'Medici',
                    key: 'users',
                    cols: 4
                },
                {
                    label: 'Indirizzo',
                    key: 'address',
                    cols: 12
                },
                {
                    label: 'Provincia',
                    key: 'province.value',
                    cols: 12
                },
                {
                    label: 'Regione',
                    key: 'region.value',
                    cols: 12
                },
                {
                    label: 'Referente',
                    key: 'managerName',
                    cols: 12
                },
                {
                    label: 'Email',
                    key: 'manager.username',
                    cols: 12
                },
                {
                    label: 'Telefono',
                    key: 'manager.phone',
                    cols: 12
                }
            ]
        }
    },

    watch: {
        userOptions: {
            handler() {
                if (!this.user.loading) {
                    this.load();
                }
            },
            deep: true
        },
        userWord: {
            handler() {
                if (!this.user.loading) {
                    this.load();
                }
            }
        }
    },

    created() {
        if (this.isOwner()) {
            this.tab = 0;
        } else {
            this.tab = parseInt(this.$route.query.tab);
        }
    },

    mounted() {

        this.userOptions.page = 1;
        this.userOptions.itemsPerPage = 10;

        this.load();
    },

    methods: {
        removeFromNetwork() {
            this.$root.$removeFromNetwork.open('Rimuovi da network', 'Sei sicuro di voler rimuovere questo centro?').then(() => {
                networkService.removeFromNetwork(this.institute.id).then(result => {
                    if (result) {
                        this.$router.push('/network');
                        this.$root.showSuccess('Centro rimosso dal network');
                    }
                }, error => {
                    this.$root.showError(error.response.data.message, error);
                });
            });
        },

        load() {
            this.user.loading = true;
            let params = requestUtil.buildParams(this.userWord, this.userOptions);
            params.institute = this.$route.params.id;

            userService.search(params).then((result) => {

                if (result.data.data) {
                    this.user.content = result.data.data.map(userService.mapForView);
                    this.user.total = result.data.total;
                }

                this.user.loading = false;
                this.progress = true;

                networkService.find(this.$route.params.id).then((result) => {
                    this.institute = networkService.mapForView(result.data);
                    this.institute.number = this.institute.instituteClinicalStudies ? this.institute.instituteClinicalStudies.length : 0;
                    this.institute.network = this.institute.hubSpokes ? this.institute.hubSpokes.length : 0;
                    this.institute.users = this.user.total;
                }).finally(() => {
                    this.progress = false;
                });
            });
        },

        confirmDelete(item) {
            userService.delete(item.id).then(result => {
                if (result) {
                    this.load();
                    this.$root.showSuccess('Medico cancellato');
                }
            }, error => {
                this.$root.showError(error.response.data.message, error);
            });
        },

        selectTab(tab) {
            this.$router.replace({query: {...this.$route.query, tab}});
        },

        changePage(page) {
            this.userOptions.page = page;
        },

        canEdit() {
            return (this.isOwner() && authService.isManager()) || networkService.userCanEdit(authService.getAuthUser(), this.institute);
        },

        isOwner() {
            return parseInt(this.$route.params.id) === authService.getAuthUser().institute;
        },

        getTitle() {

            if (authService.isPharma()) {
                return 'Dettaglio Centro Hub';
            }

            if ((authService.isManager() || authService.isUser()) && this.isOwner()) {
                return 'Anagrafica';
            }

            return 'Dettaglio Centro Collegato';
        },

        getAddUserTo() {

            if (this.isOwner()) {
                return `/institute-owner/${this.institute.id}/user/new`;
            }

            return '/network/user/new/' + this.institute.id;
        },

        getEditUserTo(item) {

            if (this.isOwner()) {
                return `/institute-owner/${this.institute.id}/user/edit/${item.id}`;
            }

            return '/network/user/edit/' + item.id + '/' + this.institute.id;
        },

        getUserLinkPrefix() {

            if (this.isOwner()) {
                return `/institute-owner/${this.institute.id}/user/detail/`;
            }

            return '/network/user/detail/'
        },

        canAddUser() {
            return authService.hasAnyRole(['pharma'])
                || (authService.hasAnyRole(['manager']) && ((this.institute && this.institute.id === authService.getAuthUser().institute)))
                || (authService.hasAnyRole(['manager', 'user']) && this.institute.isNetwork);
        },

        canEditUser(item) {
            return authService.hasAnyRole(['pharma']) && item.pharmaOwner && authService.getAuthUser().pharma === item.pharmaOwner.id
                || authService.hasAnyRole(['manager', 'user']) && item.instituteOwner && authService.getAuthUser().institute === item.instituteOwner.id
                || authService.hasAnyRole(['user', 'manager']) && item.id === authService.getAuthUser().id
        },

        canDeleteUser(item) {
            return this.canEditUser(item) && item.id !== authService.getAuthUser().id && authService.hasPermission('us.delete');
        }
    }
}
</script>
