import axios from "axios";

const userToken = 'user-token';
const userInfo = 'user-info';

export default class AuthService {

    isAuthenticated() {
        return !!(localStorage.getItem(userToken) || '');
    }

    logout() {
        localStorage.removeItem(userInfo);
        localStorage.removeItem(userToken);
    }

    login(username, password) {
        return axios.post(`api/auth/login`, {username: username, password: password});
    }

    getAuthUser() {
        return JSON.parse(localStorage.getItem(userInfo))
    }

    setAuthUser(user) {
        localStorage.setItem(userInfo, JSON.stringify(user));
    }

    hasAnyPermission(permissions) {
        return permissions.some((permission) => this.getAuthUser().permissions?.includes(permission));
    }

    hasAnyRole(roles) {
        return roles.some((role) => this.getAuthUser().role === role);
    }

    hasPermission(permission) {
        return this.getAuthUser().permissions?.includes(permission);
    }

    setToken(token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        localStorage.setItem(userToken, token);
    }

    isAdmin() {
        return this.hasAnyRole(['admin'])
    }

    isPharma() {
        return this.hasAnyRole(['pharma'])
    }

    isManager() {
        return this.hasAnyRole(['manager'])
    }

    isUser() {
        return this.hasAnyRole(['user'])
    }
}
