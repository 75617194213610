<template>
    <page-form :config="config" :model.sync="pharma" v-on:update:model="updateConfig"></page-form>
</template>

<script>

import PharmaService from "../../service/PharmaService";
import PageForm from "@/components/PageForm";

export default {
    name: 'PharmaForm',
    components: {PageForm},
    data() {
        return {
            pharma: {},
            config: {
                text: {
                    successMessage: 'Pharma salvata',
                    titleNew: 'Pharma - Nuova',
                    titleEdit: 'Pharma - Modifica',
                    name: 'Pharma'
                },
                service: new PharmaService(),
                errors: {
                    vat_number: '',
                    name: ''
                },
                fields: [
                    {
                        name: 'FormField',
                        key: 'name',
                        cols: 6,
                        placeholder: 'Ragione Sociale',
                        maxlength: 150,
                        required: true,
                        customEvents: {
                            'blur': a => {
                                this.checkName(a.target.value);
                            },
                            'input': () => {
                                this.config.errors['name'] = '';
                            }
                        }
                    },
                    {
                        name: 'FormField',
                        key: 'vat_number',
                        cols: 6,
                        placeholder: 'Partita Iva',
                        required: true,
                        maxlength: 16,
                        customEvents: {
                            'blur': a => {
                                this.checkVatNumber(a.target.value);
                            },
                            'input': () => {
                                this.config.errors['vat_number'] = '';
                            }
                        }
                    },
                    {
                        name: 'FormField',
                        key: 'address',
                        cols: 6,
                        maxlength: 150,
                        placeholder: 'Indirizzo'
                    },
                    {
                        name: 'FormField',
                        key: 'province',
                        cols: 6,
                        maxlength: 2,
                        placeholder: 'Provincia'
                    },
                    {
                        name: 'FormField',
                        key: 'city',
                        cols: 6,
                        maxlength: 150,
                        placeholder: 'Comune'
                    },
                    {
                        name: 'FormSelect',
                        key: 'license',
                        cols: 6,
                        items: [],
                        label: 'Licenza',
                        itemValue: 'id',
                        itemText: 'name',
                        required: true
                    }
                ],
                url: '/pharma'
            }
        };
    },

    methods: {
        checkVatNumber(vatNumber) {
            if (vatNumber) {
                this.config.service.checkVatNumber(this.pharma.id, vatNumber).then((result) => {
                    if (result) {
                        console.log(result);
                    }
                }, error => {
                    console.log(error.response.data);
                    this.config.errors['vat_number'] = error.response.data.message;
                })
            }
        },
        checkName(name) {
            if (name) {
                this.config.service.checkName(this.pharma.id, name).then((result) => {
                    if (result) {
                        console.log(result);
                    }
                }, error => {
                    console.log(error.response.data);
                    this.config.errors['name'] = error.response.data.message;
                })
            }
        },

        updateConfig(pharma) {
            pharma.license = pharma.license.id;
        }
    },

    mounted() {
        this.config.service.getLicenses().then(res => {
            this.config.fields.find(i => i.key === 'license').items = res.data;
        });
    }
}

</script>
