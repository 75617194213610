<template>
    <page-form :config="config" :model.sync="user" v-on:update:model="updateConfig"></page-form>
</template>

<script>

import PageForm from "@/components/PageForm";
import UserService from "../../service/UserService";
import PharmaService from "../../service/PharmaService";
import InstituteService from "../../service/InstituteService";
import ValidationUtil from "../../utils/ValidationUtil";
import AuthService from "../../service/AuthService";
import TaxonomyService from "../../service/TaxonomyService";

const pharmaService = new PharmaService();
const authService = new AuthService();
const instituteService = new InstituteService();
const validationUtil = new ValidationUtil();
const taxonomyService = new TaxonomyService();

export default {
    name: 'UserForm',
    components: {PageForm},
    data() {
        return {
            user: {},
            config: {
                text: {
                    successMessage: 'Medico salvato',
                    titleNew: 'Medico - Nuovo',
                    titleEdit: 'Medico - Modifica',
                    name: 'Medico'
                },
                service: new UserService(),
                errors: {
                    username: '',
                    first_name: '',
                    last_name: '',
                },
                fields: [
                    {
                        name: 'FormField',
                        key: 'username',
                        cols: 6,
                        placeholder: 'Email',
                        required: true,
                        maxlength: 150,
                        customEvents: {
                            'blur': a => {
                                this.checkEmail(a.target.value);
                            },
                            'input': () => {
                                this.config.errors['username'] = '';
                            }
                        }
                    },
                    {
                        name: 'FormCheckBox',
                        key: 'passwordManual',
                        cols: 6,
                        class: 'password-manual-checkbox',
                        label: 'Inserisci password (L\'utente non dovrà cambiare la password al primo accesso)',
                        change: checked => {
                            this.config.fields.find(i => i.key === 'password').hidden = !checked;
                        },
                        hidden: false
                    },
                    {
                        name: 'FormField',
                        key: 'password',
                        type: 'password',
                        maxlength: 150,
                        cols: 6,
                        placeholder: 'Password',
                        required: true,
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'firstName',
                        maxlength: 150,
                        cols: 6,
                        placeholder: 'Nome',
                        required: true
                    },
                    {
                        name: 'FormField',
                        key: 'lastName',
                        maxlength: 150,
                        cols: 6,
                        placeholder: 'Cognome',
                        required: true
                    },
                    {
                        name: 'FormSelect',
                        key: 'roles',
                        cols: 6,
                        placeholder: 'Ruolo',
                        items: [],
                        required: true,
                        itemValue: 'id',
                        itemText: 'description',
                        returnObject: true,
                        change: this.changeRole
                    },
                    {
                        name: 'FormSelect',
                        key: 'pharma',
                        cols: 6,
                        placeholder: 'Pharma',
                        items: [],
                        itemValue: 'id',
                        itemText: 'name',
                        hidden: true
                    },
                    {
                        name: 'FormAutoComplete',
                        key: 'institute',
                        cols: 6,
                        placeholder: 'Centro',
                        items: [],
                        itemValue: 'id',
                        itemText: 'name',
                        hidden: true
                    },
                    {
                        name: 'FormSelect',
                        key: 'therapeuticAreas',
                        cols: 6,
                        label: 'Aree terapeutiche',
                        items: [],
                        multiple: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'department',
                        maxlength: 150,
                        cols: 6,
                        placeholder: 'Reparto',
                        hidden: true
                    }
                ],
                url: '/user'
            }
        };
    },

    methods: {
        checkEmail(email) {
            if (email) {

                if (!validationUtil.validateEmail(email)) {
                    this.config.errors['username'] = 'Email non valida';
                    return;
                }

                this.config.service.checkEmail(this.user.id, email).then(() => {}, error => {
                    this.config.errors['username'] = error.response.data.message;
                });
            }
        },

        updateConfig(user) {
            if (user.id) {
                user.roles = user.roles[0];
                this.config.fields.find(i => i.key === 'pharma').hidden = user.roles.code !== 'pharma';
                this.config.fields.find(i => i.key === 'institute').hidden = authService.getAuthUser().role !== 'admin' || (user.roles.code !== 'user' && user.roles.code !== 'manager');
                this.config.fields.find(i => i.key === 'therapeuticAreas').hidden = (user.roles.code !== 'user' && user.roles.code !== 'manager');
                this.config.fields.find(i => i.key === 'department').hidden = (user.roles.code !== 'user' && user.roles.code !== 'manager');
                this.config.fields.find(i => i.key === 'password').hidden = true;
                this.config.fields.find(i => i.key === 'passwordManual').hidden = true;
            }
        },

        changeRole(role) {
            if (role) {
                this.config.fields.find(i => i.key === 'pharma').hidden = role.code !== 'pharma';
                this.config.fields.find(i => i.key === 'institute').hidden = authService.getAuthUser().role !== 'admin' || (role.code !== 'user' && role.code !== 'manager');
                this.config.fields.find(i => i.key === 'therapeuticAreas').hidden = (role.code !== 'user' && role.code !== 'manager');
                this.config.fields.find(i => i.key === 'department').hidden = (role.code !== 'user' && role.code !== 'manager');
            }
        }
    },

    mounted() {

        taxonomyService.findAll('THERAPEUTIC_AREA').then(res => {
            this.config.fields.find(i => i.key === 'therapeuticAreas').items = res.data;
        });

        this.config.service.getRoles().then(res => {
            this.config.fields.find(i => i.key === 'roles').items = res.data;
            if (authService.hasAnyRole(['pharma', 'manager', 'user'])) {
                this.config.fields.find(i => i.key === 'roles').items = this.config.fields.find(i => i.key === 'roles').items.filter(role => {return role.code !== 'pharma' && role.code !== 'admin'});
            }
        });

        if (authService.getAuthUser().role === 'admin') {
            pharmaService.findAll().then(res => {
                this.config.fields.find(i => i.key === 'pharma').items = res.data;
            });

            instituteService.findAll().then(res => {
                this.config.fields.find(i => i.key === 'institute').items = res.data;
            });
        }

        if (authService.hasAnyRole(['pharma', 'manager', 'user'])) {

            if (parseInt(this.$route.params.institute) === authService.getAuthUser().institute) {
                this.config.url = '/institute-owner/' + this.$route.params.institute;
            } else {
                this.config.url = '/network/detail/' + this.$route.params.institute + '?tab=2';
            }

            this.config.fields.find(i => i.key === 'password').hidden = true;
            this.config.fields.find(i => i.key === 'passwordManual').hidden = true;
            this.config.fields.find(i => i.key === 'therapeuticAreas').hidden = false;
            this.user.institute = this.$route.params.institute;
            this.config.fields.find(i => i.key === 'institute').hidden = true;
        }
    }
}

</script>
