var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page',{staticClass:"page-list",attrs:{"title":_vm.authService.hasAnyRole(['pharma']) ? 'Invita Centro Hub' : 'Invita Centro',"hide-divider":"true"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('back-to-list'),_c('search',{staticClass:"ml-8 mr-8 search-institute-field",attrs:{"placeholder":'Cerca un centro da invitare oppure aggiungi un nuovo centro'},model:{value:(_vm.word),callback:function ($$v) {_vm.word=$$v},expression:"word"}}),_c('new-button',{attrs:{"to":'/network/add',"text":'Aggiungi un centro'}})]},proxy:true},{key:"content",fn:function(){return [_c('data-list',{staticClass:"network-invitation-list",attrs:{"config":{
                                    items: _vm.institutes,
                                    loading: _vm.loading,
                                    pagination: true,
                                    page: _vm.options.page,
                                    size: _vm.options.itemsPerPage,
                                    total: _vm.total}},on:{"changePage":function($event){return _vm.changePage($event)}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.province.value)+" ")]}},{key:"subtitle",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.manager ? item.managerName : '-')+" "+_vm._s(item.manager ? ' - ' + _vm.formatUtil.fromArrayToString(item.manager.therapeuticAreas.map(function (t) { return t.value; })) : '')+" ")]}},{key:"action",fn:function(ref){
                                    var item = ref.item;
return [(!!item.manager)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","small":"","title":"Invita"},on:{"click":function($event){return _vm.invite(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-solid fa-user-plus")])],1):_vm._e()]}}])})]},proxy:true}])}),_c('dialog-content',{ref:"confirmInvitation",attrs:{"options":{btnType: 'primary', confirmText: 'Invita'}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }