import BaseService from "./BaseService";
import axios from "axios";

export default class NetworkService extends BaseService {

    constructor() {
        super('network');
    }

    removeFromNetwork(id) {
        return axios.delete(`api/${this.name}/remove-from-network/${id}`)
    }

    invite(id) {
        return axios.post(`api/${this.name}/invitation/${id}`)
    }

    searchForInvitation(params) {
        return axios.get(`api/${this.name}/invitation/${this.paramsToQueryString(params)}`);
    }

    searchForStudyInvitation(params) {
        return axios.get(`api/${this.name}/invitation-study/${this.paramsToQueryString(params)}`);
    }

    searchSelfForStudyInvitation() {
        return axios.get(`api/${this.name}/invitation-self-study/`);
    }

    inviteOnStudy(institute) {
        return axios.post(`api/${this.name}/invitation-study/`, institute);
    }

    userCanEdit(user, institute) {
        return (user.role === 'pharma' && institute.pharma?.id === user.pharma)
            || ((user.role === 'manager' || user.role === 'user') && institute.institute?.id === user.institute);
    }
}
