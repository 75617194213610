<template>
    <v-card class="pa-3 main-card">
        <div class="page">
            <div class="d-flex align-center mb-4 page-header">
                <slot name="header"></slot>
            </div>

            <v-divider v-if="!hideDivider"></v-divider>

            <div>
                <slot name="content"></slot>
            </div>
        </div>
    </v-card>
</template>

<script>

export default {
    name: "Page",
    props: [
        'title',
        'hideDivider'
    ],

    mounted() {
        this.$root.setTitle(this.title);
    }
}
</script>
