<template>
    <page :title="config.text.name" :hide-divider="config.hideDivider" :class="'page-list-panel ' + (config.class ? config.class : '')">
        <slot name="prepend-page"></slot>
        <template v-slot:header>
            <slot name="prepend-header"></slot>
            <new-button v-if="authService.hasPermission(config.permissions.create)" :to="'/' + config.text.entity +'/new'" :title="config.text.title"></new-button>
            <template v-if="$scopedSlots['filter']">
                <filter-button @click="params.showFilters = !params.showFilters" :filters="params && params.filters"></filter-button>
            </template>
            <search :placeholder="config.text.searchPlaceholder" v-model="word" class="ml-6 mr-8"></search>
            <slot name="append-header"></slot>
        </template>
        <template v-slot:content>
            <slot name="prepend-list"></slot>
            <div class="d-flex flex-wrap">
                <slot name="filter" v-if="params && params.showFilters"></slot>
                <data-list :config="{   items: content,
                                        loading: loading,
                                        pagination: true,
                                        showBorder: config.showBorder,
                                        link: true,
                                        linkPrefix: config.text.entity + '/detail/',
                                        page: options.page,
                                        size: options.itemsPerPage,
                                        total: total}" @changePage="changePage($event)">
                    <template v-if="$scopedSlots['list-icon']" v-slot:icon="{item}">
                        <slot name="list-icon" :item="item"></slot>
                    </template>
                    <template v-if="$scopedSlots['prepend-content']" v-slot:prepend-content="{item}">
                        <slot name="prepend-content" :item="item"></slot>
                    </template>
                    <template v-if="$scopedSlots['list-title']" v-slot:title="{item}">
                        <slot name="list-title" :item="item"></slot>
                    </template>
                    <template v-if="$scopedSlots['list-subtitle']" v-slot:subtitle="{item}">
                        <slot name="list-subtitle" :item="item"></slot>
                    </template>
                    <template v-if="$scopedSlots['append-content']" v-slot:append-content="{item}">
                        <slot name="append-content" :item="item"></slot>
                    </template>
                    <template v-slot:action="{item}">
                        <slot name="list-prepend-action" :item="item"></slot>
                        <edit-button v-if="authService.hasPermission(config.permissions.update) && config.isEditVisible(item)" title="Modifica" :to="'/' + config.text.entity + '/edit/' + item.id"></edit-button>
                        <delete-button v-if="authService.hasPermission(config.permissions.delete) && config.isDeleteVisible(item)" title="Elimina" @delete="confirmDelete(item)"></delete-button>
                        <slot name="list-append-action" :item="item"></slot>
                    </template>
                </data-list>
            </div>
            <slot name="append-list"></slot>
        </template>
        <slot name="append-page"></slot>
    </page>
</template>

<script>
import AuthService from "../service/AuthService";
import RequestUtil from "../utils/RequestUtil";
import Page from "./Page";
import Search from "./Search";
import NewButton from "./NewButton";
import DataList from "./DataList";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";
import FilterButton from "./FilterButton";

const requestUtil = new RequestUtil();
const authService = new AuthService();

export default {
    name: "PageListPanel",
    components: {FilterButton, Page, Search, NewButton, DataList, EditButton, DeleteButton},
    props: [
        'config'
    ],
    data() {
        return {
            word: null,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 20
            },
            total: null,
            content: [],
            authService: authService,
            params: {
                showFilters: false,
                filters: {}
            }
        }
    },

    watch: {
        options: {
            handler: 'handler',
            deep: true
        },
        word: {
            handler: 'handler',
        },
        params: {
            handler: 'handler',
            deep: true
        }
    },

    methods: {

        load() {

            this.$router.push({ path: this.config.text.entity,
                query: {
                    word: this.word,
                    options: JSON.stringify(this.options),
                    params: JSON.stringify(this.params),
                }
            }).catch(() => {});

            this.loading = true;
            this.config.service.search(requestUtil.buildParams(this.word, this.options, this.params)).then(result => {
                this.content = result.data.data;
                this.total = result.data.total;
                this.loading = false;
            });
        },

        confirmDelete(item) {
            this.config.service.delete(item.id).then(result => {
                if (result) {
                    this.load();
                    this.$root.showSuccess(this.config.text.deleteSuccessMessage);
                }
            }, error => {
                this.$root.showError(error.response.data.message, error);
            });
        },

        changePage(page) {
            this.options.page = page;
        },

        handler() {
            if (!this.loading) {
                this.load();
            }
        }
    },

    created() {
        this.word = this.$route.query.word;

        if (this.$route.query.options) {
            this.options = JSON.parse(this.$route.query.options);
        }

        if (this.$route.query.params) {
            this.params = JSON.parse(this.$route.query.params);
        }

        if (!this.config.isEditVisible) {
            this.config.isEditVisible = () => {return true};
        }

        if (!this.config.isDeleteVisible) {
            this.config.isDeleteVisible = () => {return true};
        }
    }
}
</script>
