<template>
    <page-form :config="config" :model.sync="taxonomy" @saved="saved()"></page-form>
</template>

<script>
import PageForm from "@/components/PageForm";
import TaxonomyService from "@/service/TaxonomyService";

export default {
    name: "TaxonomyForm",
    components: {PageForm},
    data() {
        return {
            configDialogObj: {},
            optionsBaseObj: {},
            taxonomy: {},
            config: {
                    text: {
                        successMessage: 'Elemento salvato',
                        titleNew: 'Taxonomy - Nuovo',
                        titleEdit: 'Taxonomy - Modifica',
                        name: 'Taxonomy'
                    },
                    hideSuccessMessage: true,
                    service: new TaxonomyService(),
                    errors: {
                        value: ''
                    },
                    fields: [],
                    url: '/taxonomy'
                }

        };
    },
    methods: {

        getFields() {
            let fields = [];
            if (this.configDialogObj.manualKey) {
                fields.push({
                    name: 'FormField',
                    key: 'key',
                    cols: 12,
                    required: true,
                    label: 'Chiave',
                    customEvents: {
                        'blur': a => {
                            this.checkKey(a.target.value);
                        },
                        'input': () => {
                            this.config.errors['key'] = '';
                        }
                    }
                });
            }
            fields.push({
                name: 'FormField',
                key: 'value',
                cols: 12,
                required: true,
                label: 'Valore',
                customEvents: {
                    'blur': a => {
                        this.checkValue(a.target.value);
                    },
                    'input': () => {
                        this.config.errors['value'] = '';
                    }
                }
            });
            if (this.configDialogObj.sortable) {
                fields.push({
                    name: 'FormField',
                    key: 'order',
                    cols: 12,
                    required: true,
                    label: 'Ordine'
                });
            }

            return fields;
        },

        checkKey(key) {
            if (key) {
                this.config.service.checkKey(this.taxonomy.type, this.taxonomy.id, key).then((result) => {
                    if (result) {
                        console.log(result);
                    }
                }, error => {
                    console.log(error.response.data);
                    this.config.errors['key'] = error.response.data.message;
                })
            }
        },
        checkValue(value) {
            if (value) {
                this.config.service.checkValue(this.taxonomy.type, this.taxonomy.id, value).then((result) => {
                    if (result) {
                        console.log(result);
                    }
                }, error => {
                    console.log(error.response.data);
                    this.config.errors['value'] = error.response.data.message;
                })
            }
        },

        saved() {
            this.$root.showSuccess('Tassonomia salvata');
            window.history.back();
        }
    },
    mounted() {
        this.taxonomy.type = this.optionsBaseObj.type;
        this.config.fields = this.getFields();
    }
}
</script>

