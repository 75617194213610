<template>
    <page-form-stepper :config="config" :model.sync="clinicalStudy" v-on:update:model="updateConfig"></page-form-stepper>
</template>

<script>

import ClinicalStudyService from "../../service/ClinicalStudyService";
import TaxonomyService from "../../service/TaxonomyService";
import PharmaService from "../../service/PharmaService";
import AuthService from "../../service/AuthService";
import PageFormStepper from "../../components/PageFormStepper";
import RequestUtil from "../../utils/RequestUtil";
import UserService from "../../service/UserService";
import NetworkService from "../../service/NetworkService";

const taxonomyService = new TaxonomyService();
const pharmaService = new PharmaService();
const authService = new AuthService();
const requestUtil = new RequestUtil();
const networkService = new NetworkService();
const userService = new UserService();

export default {
    name: 'ClinicalStudyForm',
    components: {PageFormStepper},
    data() {
        return {
            clinicalStudy: {
                pathologiesText: null
            },
            config: {
                steps: [
                    {id: 1, title: 'Dati studio'},
                    {id: 2, title: 'Ulteriori informazioni'},
                    {id: 3, title: 'Criteri di inclusione'},
                    {id: 4, title: 'Principal investigator', hidden: true}
                ],
                multiFieldXRow: true,
                text: {
                    successMessage: 'Studio salvato',
                    titleNew: 'Studio - Nuovo',
                    titleEdit: 'Studio - Modifica',
                    name: 'Studio'
                },
                service: new ClinicalStudyService(),
                errors: {
                    name: '',
                    description: ''
                },
                fields: [
                    {
                        name: 'FormField',
                        key: 'name',
                        cols: 6,
                        label: 'Titolo',
                        required: true,
                        maxlength: 150,
                        step: 1
                    },
                    {
                        name: 'FormSelect',
                        key: 'pharma',
                        cols: 6,
                        label: 'Pharma',
                        items: [],
                        itemValue: 'id',
                        itemText: 'name',
                        returnObject: true,
                        step: 1,
                        hidden: false
                    },
                    {
                        name: 'FormTextArea',
                        key: 'description',
                        cols: 12,
                        label: 'Descrizione',
                        required: true,
                        numberRows: 3,
                        step: 1
                    },
                    {
                        name: 'FormSelect',
                        key: 'status',
                        cols: 6,
                        label: 'Stato',
                        items: [],
                        itemValue: 'id',
                        itemText: 'value',
                        required: true,
                        step: 1
                    },
                    {
                        name: 'FormFieldDatePicker',
                        key: 'startDate',
                        cols: 4,
                        label: 'Data Inizio',
                        required: true,
                        step: 2
                    },
                    {
                        name: 'FormFieldDatePicker',
                        key: 'estimateEndDate',
                        cols: 4,
                        label: 'Data Presunta Fine',
                        required: true,
                        step: 2
                    },
                    {
                        name: 'FormFieldDatePicker',
                        key: 'endDate',
                        cols: 4,
                        label: 'Data Fine',
                        required: true,
                        step: 2
                    },
                    {
                        name: 'FormSelect',
                        key: 'profit',
                        cols: 6,
                        label: 'Profit',
                        items: [],
                        itemValue: 'id',
                        itemText: 'value',
                        step: 2,
                        clearable: true
                    },
                    {
                        name: 'FormLinebreak',
                        key: 'lb',
                        cols: 6,
                        step: 2,
                        hidden: false
                    },
                    {
                        name: 'FormSelect',
                        key: 'type',
                        cols: 6,
                        label: 'Tipo di studio',
                        items: [],
                        itemValue: 'id',
                        itemText: 'value',
                        required: true,
                        step: 2,
                        change: (val) => {

                            let key = this.config.fields.find(i => i.key === 'type').items.find(i => i.id === val).key;
                            if (key === 'study_type_1') {
                                this.config.fields.find(i => i.key === 'phase').hidden = false;
                                this.config.fields.find(i => i.key === 'lb1').hidden = true;
                            } else {
                                this.config.fields.find(i => i.key === 'phase').hidden = true;
                                this.config.fields.find(i => i.key === 'lb1').hidden = false;
                            }
                        }
                    },
                    {
                        name: 'FormLinebreak',
                        key: 'lb1',
                        cols: 6,
                        step: 2,
                        hidden: false
                    },
                    {
                        name: 'FormSelect',
                        key: 'phase',
                        cols: 6,
                        label: 'Fase',
                        items: [],
                        itemValue: 'id',
                        itemText: 'value',
                        required: true,
                        step: 2,
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'number',
                        type: 'number',
                        cols: 6,
                        label: 'N° Pazienti richiesti',
                        maxlength: 150,
                        required: true,
                        step: 2
                    },
                    {
                        name: 'FormSelect',
                        key: 'therapeuticAreas',
                        cols: 6,
                        label: 'Aree terapeutiche',
                        items: [],
                        multiple: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        required: true,
                        step: 2
                    },
                    {
                        name: 'FormTextArea',
                        key: 'treatment',
                        cols: 12,
                        label: 'Trattamento',
                        maxlength: 150,
                        required: true,
                        step: 2,
                        numberRows: 3
                    },
                    {
                        name: 'FormSelect',
                        key: 'primaryTargets',
                        cols: 6,
                        label: 'Obiettivi primari',
                        items: [],
                        multiple: true,
                        required: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        step: 2
                    },
                    {
                        name: 'FormSelect',
                        key: 'secondaryTargets',
                        cols: 6,
                        label: 'Obiettivi secondari',
                        items: [],
                        multiple: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        required: true,
                        step: 2
                    },
                    {
                        name: 'FormSelect',
                        key: 'sex',
                        cols: 6,
                        label: 'Sesso',
                        items: [],
                        itemValue: 'id',
                        itemText: 'value',
                        required: true,
                        step: 3
                    },
                    {
                        name: 'FormSelect',
                        key: 'ages',
                        cols: 6,
                        label: 'Età',
                        items: [],
                        multiple: true,
                        itemValue: 'id',
                        required: true,
                        itemText: 'value',
                        returnObject: true,
                        step: 3
                    },
                    {
                        name: 'FormAddText',
                        key: 'criteria',
                        cols: 12,
                        label: 'Criteri di inclusione',
                        step: 3,
                        type: 'autocomplete',
                        items: [],
                        required: true,
                        itemText: 'value',
                        itemValue: 'id',
                        search: (word) => {
                            this.config.service.searchCriteria(word).then((res) => {
                                this.config.fields.find(i => i.key === 'criteria').items = res.data;
                            });
                        }
                    },
                    {
                        name: 'FormAddText',
                        key: 'exclusionCriteria',
                        cols: 12,
                        label: 'Criteri di esclusione',
                        step: 3,
                        type: 'autocomplete',
                        items: [],
                        itemText: 'value',
                        itemValue: 'id',
                        search: (word) => {
                            this.config.service.searchExclusionCriteria(word).then((res) => {
                                this.config.fields.find(i => i.key === 'exclusionCriteria').items = res.data;
                            });
                        }
                    },
                    {
                        name: 'FormField',
                        key: 'protocol',
                        cols: 6,
                        label: 'Protocollo',
                        maxlength: 150,
                        required: true,
                        step: 3
                    },
                    {
                        name: 'FormSelect',
                        key: 'pathologies',
                        cols: 6,
                        label: 'Patologia',
                        items: [],
                        multiple: true,
                        required: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        step: 3
                    },
                    {
                        name: 'FormAddText',
                        key: 'pathologiesText',
                        cols: 12,
                        label: 'Patologia ulteriori dettagli',
                        step: 3,
                        type: 'autocomplete',
                        items: [],
                        itemText: 'value',
                        itemValue: 'id',
                        search: (word) => {
                            this.config.service.searchPathologyText(word).then((res) => {
                                this.config.fields.find(i => i.key === 'pathologiesText').items = res.data;
                            });
                        }
                    },
                    {
                        name: 'FormServerAutoComplete',
                        key: 'institute',
                        cols: 6,
                        items: [],
                        label: 'Centro',
                        itemText: 'name',
                        itemValue: 'id',
                        required: true,
                        step: 4,
                        isDisabled: () => !authService.getAuthUser().pharma,
                        returnObject: true,
                        search: (val) => {
                            let params = requestUtil.buildParams(val, { page: 1, itemsPerPage: 99999, sortBy: ['name'], sortDesc: []});
                            params.study = this.clinicalStudy.id;
                            networkService.searchForStudyInvitation(params).then(result => {
                                this.config.fields.find(i => i.key === 'institute').items = result.data.data;
                            });
                        },
                        change: (institute) => {
                            let params = requestUtil.buildParams(null, { page: 1, itemsPerPage: 99999, sortBy: ['name'], sortDesc: []});
                            params.institute = institute.id;
                            userService.search(params).then((result) => {
                                this.config.fields.find(i => i.key === 'user').items = result.data.data.map(userService.mapForView);
                            });
                        }
                    },
                    {
                        name: 'FormSelect',
                        key: 'user',
                        cols: 6,
                        step: 4,
                        required: true,
                        label: 'Medico',
                        items: [],
                        itemValue: 'id',
                        itemText: 'fullNameAndTherapeuticAreasText'
                    }
                ],
                url: '/clinical-study'
            }
        };
    },

    methods: {
        updateConfig(clinicalStudy) {
            console.log(clinicalStudy);
        }
    },

    mounted() {

        if (authService.getAuthUser().institute) {
            networkService.searchSelfForStudyInvitation().then(result => {
                this.config.fields.find(i => i.key === 'institute').items = result.data.data;
                this.clinicalStudy.institute = this.config.fields.find(i => i.key === 'institute').items[0];
                let params = requestUtil.buildParams(null, { page: 1, itemsPerPage: 99999, sortBy: ['name'], sortDesc: []});
                userService.searchSelfForInstitute(params).then((result) => {
                    this.config.fields.find(i => i.key === 'user').items = result.data.data.map(userService.mapForView);
                });
            });
        }

        if (authService.getAuthUser().role === 'admin') {
            pharmaService.findAll().then(res => {
                this.config.fields.find(i => i.key === 'pharma').items = res.data;
            });
        }

        if (authService.hasAnyRole(['pharma', 'user', 'manager'])) {
            this.config.fields.find(i => i.key === 'pharma').hidden = true;
        }

        taxonomyService.findAll('STUDY_STATUS').then(res => {
            this.config.fields.find(i => i.key === 'status').items = res.data;
        });

        taxonomyService.findAll('AGE').then(res => {
            this.config.fields.find(i => i.key === 'ages').items = res.data;
        });

        taxonomyService.findAll('TARGET').then(res => {
            this.config.fields.find(i => i.key === 'primaryTargets').items = res.data;
            this.config.fields.find(i => i.key === 'secondaryTargets').items = res.data;
        });

        taxonomyService.findAll('SEX').then(res => {
            this.config.fields.find(i => i.key === 'sex').items = res.data;
        });

        taxonomyService.findAll('STUDY_TYPE').then(res => {
            this.config.fields.find(i => i.key === 'type').items = res.data;

            if (this.clinicalStudy.type) {
                let key = res.data.find(i => i.id === this.clinicalStudy.type.id).key;
                if (key === 'study_type_1') {
                    this.config.fields.find(i => i.key === 'phase').hidden = false;
                    this.config.fields.find(i => i.key === 'lb1').hidden = true;
                } else {
                    this.config.fields.find(i => i.key === 'phase').hidden = true;
                    this.config.fields.find(i => i.key === 'lb1').hidden = false;
                }
            }
        });

        taxonomyService.findAll('PATHOLOGY').then(res => {
            this.config.fields.find(i => i.key === 'pathologies').items = res.data;
        });

        taxonomyService.findAll('PHASE').then(res => {
            this.config.fields.find(i => i.key === 'phase').items = res.data;
        });

        taxonomyService.findAll('THERAPEUTIC_AREA').then(res => {
            this.config.fields.find(i => i.key === 'therapeuticAreas').items = res.data;
        });

        taxonomyService.findAll('PROFIT').then(res => {
            this.config.fields.find(i => i.key === 'profit').items = res.data;
        });

        if (!this.$router.currentRoute.params.id) {
            this.config.steps.find(s => s.id === 4).hidden = false;
        }
    }
}

</script>
