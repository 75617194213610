<template>
    <v-form ref="form">
        <v-container>
            <slot name="prepend-form"></slot>
            <v-row v-if="multiFieldXRow">
                <v-col cols="12" :md="item.cols" v-for="(item, i) in getFields()" :key="i">
                    <component :is="resolveComponent[i]" :config="prepareItem(item)" :model="model" :errors="errors" :class="item.class" />
                </v-col>
            </v-row>
            <div v-else>
                <v-row v-for="(item, i) in getFields()" :key="i">
                    <v-col cols="12" :md="item.cols">
                        <component :is="resolveComponent[i]" :config="prepareItem(item)" :model="model" :errors="errors" :class="item.class" />
                    </v-col>
                </v-row>           
            </div>
            <div class="d-flex mt-4">
                <slot name="prepend-save"></slot>
                <v-btn v-if="!hideSave" @click="save()" color="primary" :disabled="false">{{saveText ? saveText : 'Salva'}}</v-btn>
                <slot name="append-save"></slot>
            </div>
        </v-container>
    </v-form>
</template>

<script>

export default {
    name: 'FormCustom',
    props: [
        'fields',
        'model',
        'errors',
        'multiFieldXRow',
        'hideSave',
        'saveText'
    ],
    computed: {
        resolveComponent() {
            let components = [];
            for (const element of this.getFields()) {
                components.push(() => import('./' + element.name));
            }
            return components;
        }
    },
    methods: {

        getFields() {
            return this.fields.filter(f => !f.hidden);
        },

        prepareItem(item) {
            if (!item.rules) {
                item.rules = [];
            }
            if (item.required) {
                let erroMessage = (item.placeholder ? item.placeholder : item.label) + " obbligatorio";
                if (item.name === 'FormSelect' && item.multiple) {
                    item.rules = [(v) => v.length > 0 || erroMessage];
                } else if (item.name === 'FormAddText') {
                    item.rules = [() => this.model[item.key]?.length > 0 || erroMessage];
                } else {
                    item.rules.push(v => ((v && typeof v === 'string' && v.trim() !== '') || v) || erroMessage);
                }
            }
            return item;
        },

        save() {
            if (this.$refs.form.validate() && this.isValid()) {
                this.$emit('save', this.model);
            }
        },

        validate() {
            return this.$refs.form.validate() && this.isValid();
        },

        cancel() {
            this.$emit('cancel')
        },

        isValid() {
            if (this.errors) {
                for (const element of this.getFields()) {
                    if (this.errors[element.key] && this.errors[element.key] !== '') {
                        return false;
                    }
                }
                return true;
            }
            return true;
        },

        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
}
</script>
