import BaseService from "./BaseService";
import axios from "axios";
import DateUtil from "../utils/DateUtil";

const dateUtil = new DateUtil();

export default class ClinicalStudyService extends BaseService {

    constructor() {
        super('clinical-study');
    }

    mapForView(clinicalStudy) {
        if (clinicalStudy.startDate) {
            clinicalStudy.startDate = dateUtil.fromISOStringToItaDate(clinicalStudy.startDate);
        }
        if (clinicalStudy.estimateEndDate) {
            clinicalStudy.estimateEndDate = dateUtil.fromISOStringToItaDate(clinicalStudy.estimateEndDate);
        }
        if (clinicalStudy.endDate) {
            clinicalStudy.endDate = dateUtil.fromISOStringToItaDate(clinicalStudy.endDate);
        }

        if (clinicalStudy.institutes) {
            clinicalStudy.institutes = clinicalStudy.institutes.map((ic) => {
                return {
                    id: ic.id,
                    principal: ic.principal,
                    value: ic.institute.name + ' - ' + ic.user.firstName  + ' ' + ic.user.lastName,
                    instituteOwner: ic.instituteOwner,
                    pharmaOwner: ic.pharmaOwner
                }
            });
        }

        return clinicalStudy;
    }

    getFilterValues(field) {
        return axios.get(`api/${this.name}/filter/${field}`);
    }

    getFilterValuesJoin(field) {
        return axios.get(`api/${this.name}/filter-join/${field}`);
    }

    searchCriteria(word) {
        return axios.get(`api/${this.name}/search-criteria/${word ? word : ''}`);
    }

    searchExclusionCriteria(word) {
        return axios.get(`api/${this.name}/search-exclusion-criteria/${word ? word : ''}`);
    }

    remove(id, institute) {
        return axios.delete(`api/${this.name}/remove/${id}/${institute}`);
    }

    searchPathologyText(word) {
        return axios.get(`api/${this.name}/search-pathology-text/${word ? word : ''}`);
    }

    userCanEdit(userId, clinicalStudy) {

        if (clinicalStudy.owner) {
            return true;
        }

        if (clinicalStudy.hub && clinicalStudy.instituteClinicalStudies) {
            return !!clinicalStudy.instituteClinicalStudies.find((ic) => ic.userId === userId);
        }
        return false;
    }

    getPatientNumber(clinicalStudy) {

        if (!clinicalStudy.number) {
            return '-';
        }

        if (clinicalStudy.owner || clinicalStudy.hub) {
            return clinicalStudy.patientAccepted + ' / ' + clinicalStudy.number;
        }

        return clinicalStudy.number - clinicalStudy.patientAccepted;
    }

    sendProposal(proposal) {
        return axios.post(`api/${this.name}/send-proposal/`, proposal);
    }

    confirmPatient(id, patient) {
        return axios.put(`api/${this.name}/confirm-patient/${id}`, patient);
    }

    denyPatient(id, patient) {
        return axios.put(`api/${this.name}/deny-patient/${id}`, patient);
    }

    patients(clinicalStudy) {
        return axios.get(`api/${this.name}/patients/${clinicalStudy}`);
    }

    saveRecruited(clinicalStudy) {
        return axios.put(`api/${this.name}/number-recruited/`, clinicalStudy);
    }
}
