<template>
    <page-list :config="config" ref="taxonomyPageList" class="page-taxonomy-list">
        <template v-slot:prepend-header>
            <v-select
                class="select-taxonomy"
                :items="items"
                label="Tipologia"
                v-model="itemSelected"
                item-text="label"
                item-value="type"
                @change="changeSelect($event)"
                return-object
            ></v-select>
        </template>
    </page-list>
</template>


<script>
import PageList from "@/components/PageList";
import TaxonomyService from "@/service/TaxonomyService";

export default {
    name: "Taxonomy",
    components: {PageList},
    data() {
        return {
            itemSelected: {},
            items: [],
            config: {
                text: {
                    name: 'Tassonomia',
                    entity: 'taxonomy',
                    searchPlaceholder: '',
                    deleteSuccessMessage: 'Elemento cancellato correttamente'
                },
                service: new TaxonomyService(),
                headers: [
                    {text: 'Chiave', value: 'key', width: '15%'},
                    {text: 'Valore', value: 'value', width: '40%'},
                    {text: '', value: 'action', sortable: false, width: '20%'}
                ],
                permissions: {
                    create: 'tax.create',
                    update: 'tax.update',
                    retrieve: 'tax.retrieve',
                    delete: 'tax.delete'
                },
                optionsBase: {
                    type: null
                },
                dialog: false,
                dialogConfig: {}
            }
        }
    },
    methods: {
        changeSelect(item) {
          this.config.optionsBase.type = item.type;
          this.config.dialogConfig = !item.config ? {} : JSON.parse(item.config);
        }
    },
    mounted() {
        this.config.service.findConfigs().then(res => {
            this.items = res.data;
            this.itemSelected = this.items.find(i => i.type === this.config.optionsBase.type);
        });
    }
}
</script>

