<template>
    <page-list :config="config"></page-list>
</template>

<script>

import PageList from "@/components/PageList";
import PharmaService from "@/service/PharmaService";

export default {
    name: 'Pharma',
    components: {PageList},

    data: () => ({
        config: {
            text: {
                name: 'Pharma',
                entity: 'pharma',
                searchPlaceholder: '',
                deleteSuccessMessage: 'Pharma cancellata correttamente'
            },
            service: new PharmaService(),
            headers: [
                {text: 'Ragione Sociale', value: 'name', width: '10%'},
                {text: 'Indirizzo', value: 'address', width: '10%'},
                {text: 'Provincia', value: 'province', width: '5%'},
                {text: 'Comune', value: 'city', width: '10%'},
                {text: 'Licenza', value: 'license.name', width: '10%', sortable: false},
                {text: 'Scadenza', value: 'expirationDate', width: '10%', sortable: true},
                {text: 'Hub Disp.', value: 'size', width: '10%', sortable: true},
                {text: '', value: 'action', sortable: false, width: '20%'}
            ],
            permissions: {
                create: 'ph.create',
                update: 'ph.update',
                retrieve: 'ph.retrieve',
                delete: 'ph.delete'
            }

        }
    })
}

</script>
