<template>
    <page :title="config.text.name" class="page-list">
        <template v-slot:header>
            <slot name="prepend-header"></slot>
            <new-button v-if="authService.hasPermission(config.permissions.create)" :to="'/' + config.text.entity + '/new'" :title="config.text.title"></new-button>
            <search :placeholder="config.text.searchPlaceholder" v-model="word" class="ml-4"></search>
            <slot name="append-header"></slot>
        </template>
        <template v-slot:content>
            <v-data-table :headers="config.headers"
                          :loading="loading"
                          :items="content"
                          :options.sync="options"
                          :server-items-length="total"
                          :items-per-page="10"
                          :footer-props="{'items-per-page-options': [10, 50, 100]}"
                          item-key="id">
                <template v-slot:item.action="{ item }">
                    <slot name="prepend-button" :item="item"></slot>
                    <detail-button v-if="authService.hasPermission(config.permissions.retrieve)" title="Dettaglio" :to="'/' + config.text.entity + '/detail/' + item.id"></detail-button>
                    <edit-button v-if="authService.hasPermission(config.permissions.update) && config.isEditVisible(item)" title="Modifica" :to="'/' + config.text.entity + '/edit/' + item.id"></edit-button>
                    <delete-button v-if="authService.hasPermission(config.permissions.delete) && config.isDeleteVisible(item)" title="Elimina" @delete="confirmDelete(item)"></delete-button>
                    <slot name="append-button" :item="item"></slot>
                </template>
            </v-data-table>
        </template>
    </page>
</template>

<script>
import EditButton from "@/components/EditButton";
import DeleteButton from "@/components/DeleteButton";
import Page from "@/components/Page";
import NewButton from "@/components/NewButton";
import Search from "@/components/Search";
import DetailButton from "@/components/DetailButton";
import RequestUtil from "@/utils/RequestUtil";
import AuthService from "@/service/AuthService";

const authService = new AuthService();

const requestUtil = new RequestUtil();

export default {
    name: "PageList",
    components: {EditButton, DeleteButton, Page, NewButton, Search, DetailButton},
    props: [
        'config'
    ],
    data: () => ({
        total: 0,
        options: {},
        loading: false,
        word: null,
        content: [],
        authService
    }),

    watch: {
        options: {
            handler() {
                this.load();
            },
            deep: true,
        },
        word: {
            handler() {
                if (!this.loading) {
                    this.total = 0;
                    this.options = {page: 1, itemsPerPage: 10};
                    this.load();
                }
            }
        },
        config: {
            handler() {
                this.total = 0;
                this.options = {page: 1, itemsPerPage: 10};
                this.word = null;
                this.load();
            },
            deep: true
        }
    },

    methods: {
        load() {

            this.$router.push({ path: this.config.text.entity, query: {word: this.word, options: JSON.stringify(this.options), optionsBase: JSON.stringify(this.config.optionsBase)}}).catch(() => {});

            this.loading = true;
            let optionsBase = this.config.optionsBase || {};
            this.config.service.search(requestUtil.buildParams(this.word, this.options, optionsBase)).then(result => {
                this.content = result.data.data.map(this.config.service.mapForView);
                this.total = result.data.total;
                this.loading = false;
            });
        },
        confirmDelete(item) {
            this.config.service.delete(item.id).then(result => {
                if (result) {
                    this.load();
                    this.$root.showSuccess(this.config.text.deleteSuccessMessage);
                }
            }, error => {
                this.$root.showError(error.response.data.message, error);
            });
        },
    },

    created() {
        this.word = this.$route.query.word;
        if (this.$route.query.options) {
            this.options = JSON.parse(this.$route.query.options);
        }

        if (this.$route.query.optionsBase) {
            this.config.optionsBase = JSON.parse(this.$route.query.optionsBase);
        }

        if (!this.config.isEditVisible) {
            this.config.isEditVisible = () => {return true};
        }

        if (!this.config.isDeleteVisible) {
            this.config.isDeleteVisible = () => {return true};
        }
    }
}
</script>

