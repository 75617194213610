<template>
    <v-container class="login-container" fluid fill-height>
        <v-layout>
            <v-flex>
                <v-img v-if="$root.$showLogo" class="login-logo" src="../assets/logo.svg"/>
                <v-form @submit.prevent="changePassword">
                    <v-card class="elevation-12 pa-5">
                        <v-card-text>
                            <v-text-field
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show"
                                outlined id="password" name="password" placeholder="Password"
                                :type="show ? 'text' : 'password'"
                                v-model="password">
                            </v-text-field>
                            <v-text-field
                                :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showConfirm = !showConfirm"
                                outlined id="passwordConfirm" name="passwordConfirm"
                                placeholder="Conferma password"
                                :type="showConfirm ? 'text' : 'password'"
                                v-model="passwordConfirm"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn x-large color="primary" type="submit" :loading="loading" :disabled="loading"
                                   width="100%">{{setBtnLabel}}
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

import UserService from "@/service/UserService";

const userService = new UserService();

export default {
    name: 'ChangePassword',
    props: ['type'],
    data: () => ({
        loading: false,
        password: null,
        show: false,
        passwordConfirm: null,
        showConfirm: false
    }),
    computed: {
        setBtnLabel() {
            if (this.type === 'change' || this.type === 'password') {
                return 'REIMPOSTA';
            }
            return 'CONFERMA';
        }
    },
    methods: {
        changePassword: function () {
            this.loading = true;
            if (this.type === 'change' || this.type === 'token') {
                let tokenType = 'FIRST_ACCESS';
                let to = '/first-access-password-changed';
                if (this.type === 'change') {
                    tokenType = 'RESET_PASSWORD';
                    to = '/password-changed';
                }
                this.token = this.$route.params.token;
                userService.changePassword(this.password, this.passwordConfirm, this.token, tokenType).then(() => {
                    this.$router.push(to);
                }, error => {
                    this.$root.showError(error.response.data.message, error.response.data);
                }).finally(() =>
                    this.loading = false
                );
            } else if (this.type === 'password') {
                userService.updatePassword(this.password, this.passwordConfirm).then(() => {
                    this.$router.push(`/`);
                }, error => {
                    this.$root.showError(error.response.data.message, error.response.data);
                }).finally(() =>
                    this.loading = false
                );
            }
        }
    }
}
</script>

