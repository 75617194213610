<template>
    <div>
        <v-btn class="mx-2 button-filter" color="primary" @click="$emit('click', $event)">
            <v-icon small>fa-solid fa-search</v-icon> &nbsp;Filtri <v-badge v-if="activeFiltersCount() > 0" color="#64c3d7" bordered :content="activeFiltersCount()"></v-badge>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "FilterButton",
    props: [
        'filters'
    ],
    methods: {
        activeFiltersCount() {
            return Object.keys(this.filters).filter(k => this.filters[k] != null && this.filters[k] != '').length;
        }
    }
}
</script>

