<template>
    <page-list :config="config"></page-list>
</template>

<script>

import PageList from "@/components/PageList";
import InstituteService from "@/service/InstituteService";

export default {
    name: 'Institute',
    components: {PageList},

    data: () => ({
        config: {
            text: {
                name: 'Istituto',
                entity: 'institute',
                searchPlaceholder: 'Cerca per Ragione Sociale',
                deleteSuccessMessage: 'Istituto cancellato correttamente'
            },
            service: new InstituteService(),
            headers: [
                {text: 'Ragione Sociale', value: 'name', width: '20%'},
                {text: 'Indirizzo', value: 'address', width: '20%'},
                {text: 'Provincia', value: 'province', width: '20%'},
                {text: 'Regione', value: 'region', width: '20%'},
                {text: '', value: 'action', sortable: false, width: '20%'}
            ],
            permissions: {
                create: 'ins.create',
                retrieve: 'ins.retrieve',
                update: 'ins.update',
                delete: 'ins.delete'
            }

        }
    })
}

</script>
