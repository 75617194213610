<template>
    <v-btn :to="to" class="mr-2" color="primary" :title="title">
        <v-icon :left="!!text">fa-solid fa-plus</v-icon>
        {{ text }}
    </v-btn>
</template>

<script>
export default {
    name: "NewButton",
    props: [
        'to',
        'text',
        'title'
    ]
}
</script>
