<template>
    <page-list :config="config"></page-list>
</template>

<script>

import PageList from "@/components/PageList";
import UserService from "../../service/UserService";

export default {
    name: 'User',
    components: {PageList},

    data: () => ({
        config: {
            text: {
                name: 'Utenti',
                entity: 'user',
                searchPlaceholder: 'Cerca',
                deleteSuccessMessage: 'Utente cancellato correttamente'
            },
            service: new UserService(),
            headers: [
                {text: 'Username', value: 'username', width: '15%'},
                {text: 'Cognome', value: 'lastName', width: '15%'},
                {text: 'Nome', value: 'firstName', width: '15%'},
                {text: 'Ruolo', value: 'role', width: '20%', sortable: false},
                {text: '', value: 'action', sortable: false, width: '20%'},
            ],
            permissions: {
                create: 'us.create',
                update: 'us.update',
                retrieve: 'us.retrieve',
                delete: 'us.delete'
            }

        }
    })
}

</script>
