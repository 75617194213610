<template>
    <v-text-field class="search-field" :value="value" :placeholder="placeholder ? placeholder : 'Cerca...'" @input="searchAfterDebounce($event)" prepend-inner-icon="fa-solid fa-search"></v-text-field>
</template>

<script>

import _ from 'lodash';

export default {
    props: [
        'value',
        'placeholder'
    ],
    name: "Search",

    methods: {
        searchAfterDebounce: _.debounce(
            function (newValue) {
                this.$emit('input', newValue);
            }, 300
        )
    }
}
</script>
