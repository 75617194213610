<template>
    <page-detail :config="config" :model.sync="user" v-on:update:model="updateConfig"></page-detail>
</template>

<script>

import PageDetail from "@/components/PageDetail";
import UserService from "../../service/UserService";
import AuthService from "../../service/AuthService";

const authService = new AuthService();

export default {
    name: 'PharmaDetail',
    components: {PageDetail},
    data() {
        return {
            user: {},
            config: {
                text: {
                    title: 'Medico - Dettaglio',
                    name: 'Medico'
                },
                service: new UserService(),
                fields: [
                    {
                        label: 'Username',
                        key: 'username',
                        cols: 4
                    },
                    {
                        label: 'Nome',
                        key: 'firstName',
                        cols: 4
                    },
                    {
                        label: 'Cognome',
                        key: 'lastName',
                        cols: 4
                    },
                    {
                        label: 'Ruolo',
                        key: 'role',
                        cols: 4
                    },
                    {
                        label: 'Centro',
                        key: 'institute.name',
                        cols: 4
                    },
                    {
                        label: 'Aree terapeutiche',
                        key: 'therapeuticAreas',
                        cols: 4,
                        array: true
                    },
                    {
                        label: 'Pharma',
                        key: 'pharma.name',
                        cols: 4
                    },
                    {
                        label: 'Reparto',
                        key: 'department',
                        cols: 4
                    },
                    {
                        label: 'Ultimo accesso',
                        key: 'lastLogin',
                        cols: 4
                    }
                ]
            }
        }
    },
    methods: {
        updateConfig() {
            if (authService.getAuthUser().role === 'pharma') {
                this.config.fields.find(i => i.key === 'pharma.name').hidden = true;
            }
        }
    }
}

</script>
