<template>
    <div id="app">
        <v-app id="inspire">
            <v-navigation-drawer v-if="!isLoginLayout()" v-model="drawer" app :clipped="false" :floating="false" :mini-variant="false" :permanent="false" :temporary="false">

                <v-list-item class="logo-menu" v-if="$root.$showLogo">
                    <v-list-item-icon>
                        <v-img src="./assets/logo.svg" />
                    </v-list-item-icon>
                </v-list-item>

                <v-divider class="mb-1 title-divider"/>

                <v-list nav class="side-menu">
                    <v-list-item link class="item-list background-item-list" v-if="authService.hasAnyRole(['admin'])" :to="'/user'">
                        <v-list-item-action>
                            <v-icon>fa-solid fa-users</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Utenti
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="item-list background-item-list" v-if="authService.hasAnyRole(['admin'])" :to="'/pharma'">
                        <v-list-item-action>
                            <v-icon>fa-solid fa-pills</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Pharma
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="item-list background-item-list" v-if="authService.hasAnyRole(['pharma']) || authService.getAuthUser().hub && authService.hasAnyRole(['manager', 'user'])" :to="'/network'">
                        <v-list-item-action>
                            <v-icon>fa-solid fa-diagram-project</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Il tuo network
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="item-list background-item-list" v-if="authService.hasAnyRole(['pharma', 'admin', 'user', 'manager'])" :to="'/clinical-study'">
                        <v-list-item-action>
                            <v-icon>fa-solid fa-book-medical</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Studi Clinici
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="item-list background-item-list" v-if="authService.hasAnyRole(['manager', 'user'])" :to="'/institute-owner/' + authService.getAuthUser().institute">
                        <v-list-item-action>
                            <v-icon>fa-solid fa-users-gear</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Anagrafica
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link class="item-list background-item-list" v-if="authService.isAdmin()" :to="'/taxonomy'">
                        <v-list-item-action>
                            <v-icon>fa-solid fa-table-list</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Tassonomia
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list nav class="user-panel">
                    <v-menu top :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item link v-bind="attrs" v-on="on" class="item-list background-item-list">
                                <v-list-item-action>
                                    <v-icon>fa-solid fa-user</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title :title="authService.getAuthUser().firstName + ' ' + authService.getAuthUser().lastName">
                                        {{authService.getAuthUser().firstName + ' ' + authService.getAuthUser().lastName}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle :title="authService.getAuthUser().username">{{authService.getAuthUser().username}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-list>
                            <v-list-item link @click="logout">
                                <v-list-item-action>
                                    <v-icon>fa-solid fa-arrow-right-from-bracket</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list>
            </v-navigation-drawer>

            <v-app-bar app :clipped-left="false" v-if="!isLoginLayout()" height="70">
                <v-app-bar-nav-icon class="icon-app" elevation="1" fab small @click.stop="drawer = !drawer">
                    <v-icon>
                        mdi-dots-vertical
                    </v-icon>
                </v-app-bar-nav-icon>

                <v-toolbar-title class="font-weight-light container-title" v-text="title" />
            </v-app-bar>

            <v-main :class="{ 'notransition': isLoginLayout() }">
                <slide-panel ref="slidePanel"></slide-panel>
                <dialog-content :options="{}" ref="confirm"></dialog-content>
                <dialog-content :options="{btnType: 'primary', confirmText: 'Rimuovi'}" ref="removeFromNetwork"></dialog-content>
                <dialog-content :options="{btnType: 'primary', confirmText: 'Chiudi', hideCancel: true}" ref="invitationSuccess">
                    <template v-slot:content>
                        <span>Il referente del centro ha ricevuto un messaggio di posta elettronica con l'invito e tutte le informazioni necessarie per l'accesso.<br><br>
                        Il centro &egrave; visibile nella pagina "Il tuo network".<br></span>
                    </template>
                </dialog-content>

                <snackbar ref="snackbar"></snackbar>

                <v-container fluid>
                    <v-row>
                        <v-col class="main-container">
                            <router-view :key="$route.path"></router-view>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
        </v-app>
    </div>
</template>

<script>

import Snackbar from "./components/Snackbar";
import DialogContent from "@/components/DialogContent";
import AuthService from "./service/AuthService";
import axios from "axios";
import SlidePanel from "./components/SlidePanel";

export default {

    components: {DialogContent, Snackbar, SlidePanel},

    props: {
        source: String,
    },

    data: () => ({
        authService: new AuthService(),
        drawer: null,
        title: ''
    }),

    watch:{
        $route () {
            this.$root.$slidePanel.close();
        }
    },

    created() {

        axios.interceptors.response.use(undefined, (err) => {

            if (err.response && err.response.status === 403 && err.response.data.message === 'not-confirmed') {
                this.$router.push({ path: '/update-password' });
                return;
            }

            if (err.response && (err.response.status === 401 || err.response.status === 403) && this.$router.currentRoute.path !== '/login') {
                this.authService.logout();
                this.$router.push({ path: '/login?redirect=' + this.$router.currentRoute.path });
                return;
            }
            throw err;
        });

        this.$root.setTitle = (title) => {
            this.title = title;
        }

        this.$root.$showLogo = true;
    },

    mounted() {

        this.$root.$slidePanel = this.$refs.slidePanel;
        this.$root.$snackbar = this.$refs.snackbar;
        this.$root.$confirm = this.$refs.confirm;
        this.$root.$removeFromNetwork = this.$refs.removeFromNetwork;
        this.$root.$invitationSuccess = this.$refs.invitationSuccess;

        this.$root.showSuccess = function(message, redirect) {
            this.$root.$snackbar.show(message, 'green');
            if (redirect) {
                this.$router.push({path: redirect});
            }
        }

        this.$root.showError = function(message, error) {
            this.$root.$snackbar.show(message, 'red');
            console.log(error);
        }

        this.$root.showConfirm = function(title, message, component, event) {
            this.$root.$confirm.open(title, message).then(() => {
                component.$emit(event);
            });
        }

        this.$root.showSlidePanel = function(title, message) {
            this.$root.$slidePanel.open(title, message);
        }
    },

    methods: {
        logout() {
            this.authService.logout();
            this.$router.push({ path: '/login' });
        },

        isLoginLayout() {
            return !this.authService.isAuthenticated() || this.$route.path === '/update-password';
        }
    }
}
</script>

<style>
    @import './assets/style/style.css';
</style>
