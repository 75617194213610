<template>
    <div>
        <div class="font-weight-regular detail-field-label">
            <span>{{ config.label }}</span> <help-icon v-if="config.text" :title="config.label" :text="config.text"></help-icon>
        </div>
        <div class="font-weight-light">
            <span v-if="config.array"><v-chip v-for="field of value" class="mt-1 mr-2" :close="config.isCloseVisible && config.isCloseVisible(field)" @click:close="config.onClose(field.id, model)" color="primary" small label :key="field.id">{{field.value}}</v-chip></span>
            <span v-if="!config.array">{{ value ? value :  '-' }}</span>
        </div>
    </div>
</template>

<script>

import HelpIcon from "./HelpIcon";
export default {
    name: "DetailField",
    components: {HelpIcon},
    props: ['config', 'model'],
    computed: {
        value() {
            let keys = this.config.key.split('.');
            let value = this.model[keys[0]];
            for (let i = 1; i < keys.length; i++) {
                if (value) {
                    value = value[keys[i]];
                }
            }
            return value;
        }
    },
    methods: {
        showPanel() {
            this.$root.showSlidePanel(this.config.label, this.config.text);
        }
    }
}
</script>
