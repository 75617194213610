<template>
    <v-btn :to="to" class="mx-2" small color="primary" :title="title">
        <v-icon small :left="text">fa-solid fa-pencil</v-icon>
        {{ text }}
    </v-btn>
</template>

<script>
export default {
    name: "EditButton",
    props: [
        'to',
        'text',
        'title'
    ]
}
</script>
