<template>
    <div class="filter-panel">
        <v-card elevation="1">
            <v-card-text>
                <form-custom multi-field-x-row="true" :fields="fields" :hide-save="true" :model="model">
                    <template v-slot:prepend-save>
                        <v-btn elevation="2" @click="$emit('clear', $event)" title="Pulisci filtri"><v-icon small>fa-solid fa-eraser</v-icon></v-btn>
                    </template>
                </form-custom>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import FormCustom from "./FormCustom";

export default {
    name: "FilterForm",
    components: {FormCustom},
    props: [
        'fields',
        'model'
    ]
}
</script>

