<template>
    <page-list-panel ref="pageListComponent" :config="config" class="page-list-panel-study">
        <template v-slot:filter>
            <filter-form :fields="filterFields" :model="filters" @clear="$refs.pageListComponent.params.filters = {}; filters = {}"></filter-form>
        </template>
        <template v-slot:list-icon="{item}" v-if="authService.hasAnyRole(['pharma'])">
            <v-icon v-if="item.status.key === 'open'">fa-solid fa-user-check</v-icon>
            <v-icon v-if="item.status.key === 'closed'">fa-solid fa-lock</v-icon>
        </template>
        <template v-slot:list-icon="{item}" v-if="authService.hasAnyRole(['user', 'manager'])">
            <v-icon v-if="item.status.key === 'open' && item.pharma !== null">fa-solid fa-pills</v-icon>
            <v-icon v-if="item.status.key === 'open' && item.pharma === null">fa-solid fa-stethoscope</v-icon>
            <v-icon v-if="item.status.key === 'closed'">fa-solid fa-lock</v-icon>
        </template>
        <template v-slot:list-title="{item}">
            <span :title="item.name">{{item.name}}</span>
        </template>
        <template v-slot:list-subtitle="{item}">
            <v-chip v-for="pat of item.pathologies" class="mt-1 mr-2 hidden-md-and-down" small label :key="pat.id">{{pat.value}}</v-chip>
        </template>
        <template v-slot:append-content="{item}">
            <div class="list-study-detail-panel">
                <div class="list-study-detail-panel-institute hidden-md-and-down">
                    <v-icon>fa-solid fa-hospital</v-icon>
                    <span class="ml-5">{{item.instituteClinicalStudies && item.instituteClinicalStudies.length ? item.instituteClinicalStudies.length : '-'}}</span>
                </div>
                <div class="list-study-detail-panel-user">
                    <v-icon>fa-solid fa-users</v-icon>
                    <span class="ml-5">{{config.service.getPatientNumber(item)}}</span>
                </div>
                <div class="list-study-detail-panel-date hidden-md-and-down">
                    <v-icon>fa-solid fa-calendar</v-icon>
                    <span class="ml-5">{{item.startDate ? dateUtil.fromISOStringToItaDate(item.startDate) : '-'}}</span>&nbsp;-&nbsp;<span>{{item.estimateEndDate ? dateUtil.fromISOStringToItaDate(item.estimateEndDate) : '-'}}</span>
                </div>
            </div>
        </template>
    </page-list-panel>
</template>

<script>
import ClinicalStudyService from "../../service/ClinicalStudyService";
import FormatUtil from "../../utils/FormatUtil";
import PageListPanel from "../../components/PageListPanel";
import DateUtil from "../../utils/DateUtil";
import AuthService from "../../service/AuthService";
import FilterForm from "../../components/FilterForm";
import TaxonomyService from "../../service/TaxonomyService";

const formatUtil = new FormatUtil();
const dateUtil = new DateUtil();
const authService = new AuthService();
const taxonomyService = new TaxonomyService();

export default {
    name: "PageList",
    components: {PageListPanel, FilterForm},
    data() {
        return {
            filters: {},
            filterFields: [
                {
                    name: 'FormSelect',
                    key: 'status',
                    cols: 3,
                    label: 'Stato',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'status', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'type',
                    cols: 3,
                    label: 'Tipo',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    multiple: true,
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'type', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'phase',
                    cols: 3,
                    label: 'Fase',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    multiple: true,
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'phase', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'sex',
                    cols: 3,
                    label: 'Sesso',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'sex', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'age',
                    cols: 3,
                    label: 'Età',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    multiple: true,
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'age', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'pathology',
                    cols: 3,
                    label: 'Patologia',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    multiple: true,
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'pathology', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'therapeutic_area',
                    cols: 3,
                    label: 'Area Terapeutica',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    multiple: true,
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'therapeutic_area', value);
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'criteria',
                    cols: 3,
                    label: 'Criteri di inclusione',
                    items: [],
                    itemValue: 'id',
                    itemText: 'value',
                    multiple: true,
                    dense: true,
                    clearable: true,
                    change: (value) => {
                        this.$set(this.$refs.pageListComponent.params.filters, 'criteria', value);
                    }
                }
            ],
            formatUtil,
            dateUtil,
            authService,
            config: {
                hideDivider: true,
                text: {
                    name: 'Studi Clinici',
                    entity: 'clinical-study',
                    searchPlaceholder: 'Cerca',
                    deleteSuccessMessage: 'Studio cancellato correttamente'
                },
                service: new ClinicalStudyService(),
                permissions: {
                    create: 'cs.create',
                    update: 'cs.update',
                    retrieve: 'cs.retrieve',
                    delete: 'cs.delete'
                },
                isDeleteVisible: (item) => {
                    return item.owner;
                },
                isEditVisible: (item) => {
                    return item.owner || (item.hub && this.config.service.userCanEdit(authService.getAuthUser().id, item));
                }
            },
        }
    },

    mounted() {

        this.filters = this.$refs.pageListComponent.params.filters;

        taxonomyService.findAll('STUDY_STATUS').then(res => {
            this.filterFields.find(i => i.key === 'status').items = res.data;
        });

        taxonomyService.findAll('STUDY_TYPE').then(res => {
            this.filterFields.find(i => i.key === 'type').items = res.data;
        });

        taxonomyService.findAll('PHASE').then(res => {
            this.filterFields.find(i => i.key === 'phase').items = res.data;
        });

        taxonomyService.findAll('SEX').then(res => {
            this.filterFields.find(i => i.key === 'sex').items = res.data;
        });

        taxonomyService.findAll('AGE').then(res => {
            this.filterFields.find(i => i.key === 'age').items = res.data;
        });

        taxonomyService.findAll('PATHOLOGY').then(res => {
            this.filterFields.find(i => i.key === 'pathology').items = res.data;
        });

        taxonomyService.findAll('THERAPEUTIC_AREA').then(res => {
            this.filterFields.find(i => i.key === 'therapeutic_area').items = res.data;
        });

        taxonomyService.findAll('CRITERIA').then(res => {
            this.filterFields.find(i => i.key === 'criteria').items = res.data;
        });
    }
}
</script>

