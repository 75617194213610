<template>
    <v-container class="recover-password-container" fluid fill-height>
        <v-layout>
            <v-flex>
                <v-img v-if="$root.$showLogo" class="login-logo" src="../assets/logo.svg" />
                <v-form @submit.prevent="recoverPassword">
                    <v-card class="elevation-12 pa-5">
                        <v-card-text>
                            <v-text-field outlined id="username" name="username" placeholder="Email" type="text" v-model="username"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn x-large color="primary" type="submit" :loading="loading" :disabled="loading" width="100%">IMPOSTA PASSWORD</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

import UserService from "@/service/UserService";

const userService = new UserService();

export default {
    name: "RecoverPassword",
    data: () => ({
        loading: false,
        username: null
    }),
    methods: {
        recoverPassword: function () {
            this.loading = true;
            userService.recoverPassword(this.username).then(() => {
                this.$router.push(`/recover-password-confirmed`);
            }, error => {
                this.$root.showError(error.response.data.message, error.response.data);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

