<template>
    <v-container class="login-container" fluid fill-height>
        <v-layout>
            <v-flex>
                <v-img v-if="$root.$showLogo" class="login-logo" src="../assets/logo.svg"/>
                <v-form @submit.prevent="login">
                    <v-card class="elevation-12 pa-5">
                        <v-card-text>
                            <v-text-field outlined id="username" name="username" placeholder="Email" type="text"
                                          v-model="username">
                            </v-text-field>
                            <v-text-field
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show"
                                outlined id="password" name="password" placeholder="Password"
                                :type="show ? 'text' : 'password'"
                                v-model="password">
                            </v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn x-large color="primary" type="submit" :loading="loading" :disabled="loading"
                                   width="100%">Accedi
                            </v-btn>
                        </v-card-actions>
                        <v-row class="mt-0">
                            <v-col class="d-flex justify-center">
                                <router-link to="/recover-password" class="forgot-password-text">Password dimenticata?</router-link>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

import AuthService from "../service/AuthService";

const authService = new AuthService();

export default {
    name: "Login",
    props: {
        source: String,
    },
    data: () => ({
        loading: false,
        username: null,
        password: null,
        show: false
    }),

    methods: {
        login: function () {
            this.loading = true;
            authService.login(this.username, this.password).then((result) => {
                authService.setToken(result.data.accessToken);
                authService.setAuthUser(result.data.userInfo);
                this.$router.push('/');
            }, error => {
                this.$root.showError('Dati di accesso non validi', error);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
