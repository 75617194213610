var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{class:'page-list-panel ' + (_vm.config.class ? _vm.config.class : ''),attrs:{"title":_vm.config.text.name,"hide-divider":_vm.config.hideDivider},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("prepend-header"),(_vm.authService.hasPermission(_vm.config.permissions.create))?_c('new-button',{attrs:{"to":'/' + _vm.config.text.entity +'/new',"title":_vm.config.text.title}}):_vm._e(),(_vm.$scopedSlots['filter'])?[_c('filter-button',{attrs:{"filters":_vm.params && _vm.params.filters},on:{"click":function($event){_vm.params.showFilters = !_vm.params.showFilters}}})]:_vm._e(),_c('search',{staticClass:"ml-6 mr-8",attrs:{"placeholder":_vm.config.text.searchPlaceholder},model:{value:(_vm.word),callback:function ($$v) {_vm.word=$$v},expression:"word"}}),_vm._t("append-header")]},proxy:true},{key:"content",fn:function(){return [_vm._t("prepend-list"),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.params && _vm.params.showFilters)?_vm._t("filter"):_vm._e(),_c('data-list',{attrs:{"config":{   items: _vm.content,
                                    loading: _vm.loading,
                                    pagination: true,
                                    showBorder: _vm.config.showBorder,
                                    link: true,
                                    linkPrefix: _vm.config.text.entity + '/detail/',
                                    page: _vm.options.page,
                                    size: _vm.options.itemsPerPage,
                                    total: _vm.total}},on:{"changePage":function($event){return _vm.changePage($event)}},scopedSlots:_vm._u([(_vm.$scopedSlots['list-icon'])?{key:"icon",fn:function(ref){
                                    var item = ref.item;
return [_vm._t("list-icon",null,{"item":item})]}}:null,(_vm.$scopedSlots['prepend-content'])?{key:"prepend-content",fn:function(ref){
                                    var item = ref.item;
return [_vm._t("prepend-content",null,{"item":item})]}}:null,(_vm.$scopedSlots['list-title'])?{key:"title",fn:function(ref){
                                    var item = ref.item;
return [_vm._t("list-title",null,{"item":item})]}}:null,(_vm.$scopedSlots['list-subtitle'])?{key:"subtitle",fn:function(ref){
                                    var item = ref.item;
return [_vm._t("list-subtitle",null,{"item":item})]}}:null,(_vm.$scopedSlots['append-content'])?{key:"append-content",fn:function(ref){
                                    var item = ref.item;
return [_vm._t("append-content",null,{"item":item})]}}:null,{key:"action",fn:function(ref){
                                    var item = ref.item;
return [_vm._t("list-prepend-action",null,{"item":item}),(_vm.authService.hasPermission(_vm.config.permissions.update) && _vm.config.isEditVisible(item))?_c('edit-button',{attrs:{"title":"Modifica","to":'/' + _vm.config.text.entity + '/edit/' + item.id}}):_vm._e(),(_vm.authService.hasPermission(_vm.config.permissions.delete) && _vm.config.isDeleteVisible(item))?_c('delete-button',{attrs:{"title":"Elimina"},on:{"delete":function($event){return _vm.confirmDelete(item)}}}):_vm._e(),_vm._t("list-append-action",null,{"item":item})]}}],null,true)})],2),_vm._t("append-list")]},proxy:true}],null,true)},[_vm._t("prepend-page"),_vm._t("append-page")],2)}
var staticRenderFns = []

export { render, staticRenderFns }