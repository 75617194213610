<template>
    <page :title="title" class="page-form">
        <template v-slot:header>
            <back-to-list :title="config.text.name"></back-to-list>
        </template>
        <template v-slot:content>
            <form-custom :fields="config.fields" :multi-field-x-row="config.multiFieldXRow" :model="model" :errors="config.errors" @save="save"></form-custom>
        </template>
    </page>
</template>

<script>
import BackToList from "@/components/BackToList";
import Page from "@/components/Page";
import FormCustom from "@/components/FormCustom";

export default {
    name: "PageForm",
    components: {BackToList, Page, FormCustom},
    props: [
        'config',
        'model'
    ],
    computed: {
        title() {
            if (this.$route.params.id) {
                return this.config.text.titleEdit;
            }
            return this.config.text.titleNew;
        }
    },
    methods: {
        save(item) {
            this.config.service.save(item).then((result) => {
                if (result && !this.config.hideSuccessMessage) {
                    this.$root.showSuccess(this.config.text.successMessage, this.config.url);
                }
                this.$emit('saved', result);
            }, error => {
                this.$root.showError(error.response.data.message, error);
            }).finally(() => {
                this.progress = false;
            });
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.progress = true;

            this.config.service.find(this.$route.params.id).then((result) => {
                this.$emit('update:model', result.data);
            }).finally(() => {
                this.progress = false;
            });
        }
    }
}
</script>
