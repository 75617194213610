<template>
    <v-icon class="ml-2" @click="showPanel()">fa-solid fa-circle-info</v-icon>
</template>

<script>
export default {
    name: "HelpIcon",
    props: ['title', 'text'],
    methods: {
        showPanel() {
            this.$root.showSlidePanel(this.title, this.text);
        }
    }
}
</script>

<style scoped>

</style>
