<template>
    <page :title="config.text.title" class="page-detail">
        <template v-slot:header>
            <slot name="prepend-header"></slot>
            <back-to-list :title="config.text.name"></back-to-list>
            <slot name="append-header"></slot>
        </template>
        <template v-slot:content>
            <v-tabs>
                <slot name="before-tab"></slot>
                <v-tab v-for="tab of config.tabs" :key="tab.id">
                    <v-icon left>
                        {{tab.icon}}
                    </v-icon>
                    {{tab.title}}
                </v-tab>
                <slot name="after-tab"></slot>
                <slot name="before-tab-item"></slot>
                <v-tab-item v-for="tab of config.tabs" :key="tab.id" class="notransition">
                    <v-divider class="hidden-sm-and-down" vertical></v-divider>
                    <detail-custom class="ma-4" :model="model" :fields="getFields(tab.id)"></detail-custom>
                </v-tab-item>
                <slot name="after-tab-item"></slot>
            </v-tabs>
        </template>
    </page>
</template>

<script>
import BackToList from "@/components/BackToList";
import Page from "@/components/Page";
import DetailCustom from "@/components/DetailCustom";
import AuthService from "../service/AuthService";

const authService = new AuthService();

export default {
    name: "PageDetailTab",
    components: {BackToList, Page, DetailCustom},
    props: [
        'config',
        'model'
    ],
    data: () => ({
        authService
    }),

    methods: {
        getFields(tab) {
            return this.config.fields.filter(f => f.tab && f.tab === tab);
        }
    },

    mounted() {

        if (this.$route.params.id) {

            this.progress = true;

            this.config.service.find(this.$route.params.id).then((result) => {
                result.data = this.config.service.mapForView(result.data);
                this.$emit('update:model', result.data);
            }).finally(() => {
                this.progress = false;
            });
        }
    }
}
</script>
