<template>
    <h1>Home</h1>
</template>

<script>

import AuthService from "../service/AuthService";

const authService = new AuthService();

export default {
    name: 'Home',
    created() {
        if (authService.hasAnyRole(['pharma']) || (authService.hasAnyRole(['manager', 'user']) && authService.getAuthUser().hub)) {
            this.$router.push('/network');
            return;
        }
        if (authService.hasAnyRole(['manager', 'user'])) {
            this.$router.push('/clinical-study');
            return;
        }
        this.$router.push('/user');
    }
}

</script>
