import _ from 'lodash';

export default class RequestUtil {

    buildParams(word, options, params) {

        let queryParams = {...params} || {};

        if (queryParams.filters) {
            _.merge(queryParams, queryParams.filters);
            delete queryParams.filters;
        }

        if (word) {
            queryParams.keyword = word;
        }

        if (options) {
            queryParams.page = options.page;
            queryParams.size = options.itemsPerPage;
            if (options.sortBy && options.sortBy[0]) {
                queryParams.sort = options.sortBy[0];
                queryParams.direction = options.sortDesc[0] ? 'DESC' : 'ASC';
            }
        }

        return queryParams;
    }
}
