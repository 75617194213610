<template>
    <page :title="config.text.title" class="page-detail">
        <template v-slot:header>
            <back-to-list :title="config.text.name"></back-to-list>
        </template>
        <template v-slot:content>
            <detail-custom class="ma-4" :model="model" :fields="config.fields"></detail-custom>
        </template>
    </page>
</template>

<script>
import BackToList from "@/components/BackToList";
import Page from "@/components/Page";
import DetailCustom from "@/components/DetailCustom";

export default {
    name: "PageDetail",
    components: {BackToList, Page, DetailCustom},
    props: [
        'config',
        'model'
    ],
    mounted() {

        if (this.$route.params.id) {

            this.progress = true;

            this.config.service.find(this.$route.params.id).then((result) => {
                result.data = this.config.service.mapForView(result.data);
                this.$emit('update:model', result.data);
            }).finally(() => {
                this.progress = false;
            });
        }
    }
}
</script>
