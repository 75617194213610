<template>
    <v-dialog v-model="dialog" :width="options.width ? options.width : 500" @keydown.esc="cancel">
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text v-if="message">{{ message }}</v-card-text>
            <v-card-text v-if="this.$slots.content">
                <slot name="content"></slot>
            </v-card-text>
            <v-card-actions v-if="!options.hideActions">
                <v-btn v-if="!options.hideConfirm" @click="agree" :color="options.btnType ? options.btnType : 'error'">
                    {{ options.confirmText ? options.confirmText : 'Elimina' }}
                </v-btn>
                <v-btn v-if="!options.hideCancel" @click="cancel">
                    {{ options.abortText ? options.abortText : 'Annulla' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "DialogContent",
        props: {
            options: {}
        },
        data: () => ({
            dialog : false,
            title: String,
            message: String
        }),
        methods: {
            open(title, message, options) {
                this.dialog = true;
                this.title = title;
                this.message = message;
                if (options) {
                    this.options = Object.assign(this.options, options);
                }
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                })
            },

            hide() {
                this.dialog = false;
            },

            agree() {
                this.resolve(true);
                this.dialog = false;
            },

            cancel() {
                this.dialog = false;
            }
        }
    }
</script>
