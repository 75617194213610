<template>
    <v-container fluid>
        <v-row>
            <template v-for="item in getFields()">
                <v-col cols="12" :md="item.cols" :key="item.key">
                    <detail-field :config="item" :model="model"></detail-field>
                </v-col>
                <v-col v-if="item.break" cols="12" :md="12 - item.cols" :key="item.key"></v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import DetailField from "./DetailField";

export default {
    name: "DetailCustom",
    components: {DetailField},
    props: ['model', 'fields'],
    methods: {
        getFields() {
            return this.fields.filter(f => !f.hidden);
        }
    }
}
</script>
