<template>
    <v-container class="login-container" fluid fill-height>
        <v-layout>
            <v-flex>
                <v-img v-if="$root.$showLogo" class="login-logo" src="../assets/logo.svg" />
                <v-card class="elevation-12 pa-5">
                    <div class="text-h5 transition-swing"> <v-icon x-large>mdi-check-bold</v-icon> {{message}}</div>
                    <br>
                    <v-card-actions>
                        <v-btn x-large color="primary" to="/login"
                               width="100%">ACCEDI
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "PasswordChanged",
    props: ['message']
}
</script>

