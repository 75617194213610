import BaseService from "./BaseService";
import axios from "axios";
import DateUtil from "../utils/DateUtil";
import FormatUtil from "../utils/FormatUtil";

const dateUtil = new DateUtil();
const formatUtil = new FormatUtil();

export default class UserService extends BaseService {

    constructor() {
        super('user');
    }

    mapForView(user) {
        user.role = user.roles[0]?.description;
        user.fullName = user.firstName  + ' ' +  user.lastName;
        if (user.lastLogin) {
            user.lastLogin = dateUtil.fromISOStringToItaDateTime(user.lastLogin);
        }
        if (user.therapeuticAreas) {
            user.therapeuticAreasText = formatUtil.fromArrayToString(user.therapeuticAreas, 'value');
            user.fullNameAndTherapeuticAreasText = user.fullName + (user.therapeuticAreas.length > 0 ? ' (' + user.therapeuticAreasText + ')' : '');
        }
        return user;
    }

    checkEmail(userId, email) {
        return axios.get(`api/user/check-email/${userId}/${email}`)
    }

    async getRoles() {
        return axios.get(`api/user/roles`)
    }

    recoverPassword(email) {
        return axios.post(`api/user/recover-password`, {email})
    }

    changePassword(password, passwordConfirm, token, tokenType) {
        return axios.put(`api/user/change-password`, {password, passwordConfirm, token, tokenType})
    }

    updatePassword(password, passwordConfirm) {
        return axios.put(`api/user/update-password`, {password, passwordConfirm})
    }

    searchSelfForInstitute(params) {
        return axios.get(`api/${this.name}/self-for-institute/${this.paramsToQueryString(params)}`);
    }
}
