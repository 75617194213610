<template>
    <page-detail :config="config" :model.sync="institute"></page-detail>
</template>

<script>

import PageDetail from "@/components/PageDetail";
import InstituteService from "@/service/InstituteService";

export default {
    name: 'InstituteDetail',
    components: {PageDetail},
    data() {
        return {
            institute: {},
            config: {
                text: {
                    title: 'Istituto - Dettaglio',
                    name: 'Istituto'
                },
                service: new InstituteService(),
                fields: [
                    {
                        label: 'Id',
                        key: 'id',
                        cols: 4
                    },
                    {
                        label: 'Ragione Sociale',
                        key: 'name',
                        cols: 4
                    },
                    {
                        label: 'Indirizzo',
                        key: 'address',
                        cols: 4
                    },
                    {
                        label: 'Provincia',
                        key: 'province',
                        cols: 4
                    },
                    {
                        label: 'Regione',
                        key: 'region',
                        cols: 4
                    }
                ]
            }
        }
    }
}

</script>
