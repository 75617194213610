import axios from "axios";
import BaseService from "@/service/BaseService";

export default class TaxonomyService extends BaseService {

    constructor() {
        super('taxonomy');
    }

    findAllFlat(type) {
        return axios.get(`api/taxonomy-public/flat/${type}`);
    }

    findAll(type) {
        return axios.get(`api/taxonomy-public/${type}`);
    }

    findAllForKey(type, key) {
        return axios.get(`api/taxonomy-public/${type}/${key}/true`);
    }

    findAllChildrenFlat(type, key) {
        return axios.get(`api/taxonomy-public/children-flat/${type}/${key}`);
    }

    findConfigs() {
        return axios.get(`api/taxonomy/configs`);
    }

    checkValue(type, id, value) {
        return axios.get(`api/taxonomy/check-value/${type}/${id}/${value}`);
    }

    checkKey(type, id, key) {
        return axios.get(`api/taxonomy/check-key/${type}/${id}/${key}`);
    }
}
