import BaseService from "./BaseService";
import axios from "axios";
import DateUtil from "../utils/DateUtil";

const dateUtil = new DateUtil();

export default class PharmaService extends BaseService {

    constructor() {
        super('pharma');
    }

    getLicenses() {
        return axios.get(`api/${this.name}/license`);
    }

    checkVatNumber(pharmaId, vatNumber) {
        return axios.get(`api/pharma/check-vat-number/${pharmaId}/${vatNumber}`)
    }

    checkName(pharmaId, name) {
        return axios.get(`api/pharma/check-name/${pharmaId}/${name}`)
    }

    mapForView(pharma) {
        pharma.expirationDate =  dateUtil.fromISOStringToItaDate(pharma.expirationDate);
        return pharma;
    }
}
