var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-list-panel',{ref:"pageListComponent",staticClass:"page-list-panel-study",attrs:{"config":_vm.config},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('filter-form',{attrs:{"fields":_vm.filterFields,"model":_vm.filters},on:{"clear":function($event){_vm.$refs.pageListComponent.params.filters = {}; _vm.filters = {}}}})]},proxy:true},(_vm.authService.hasAnyRole(['user', 'manager']))?{key:"list-icon",fn:function(ref){
var item = ref.item;
return [(item.status.key === 'open' && item.pharma !== null)?_c('v-icon',[_vm._v("fa-solid fa-pills")]):_vm._e(),(item.status.key === 'open' && item.pharma === null)?_c('v-icon',[_vm._v("fa-solid fa-stethoscope")]):_vm._e(),(item.status.key === 'closed')?_c('v-icon',[_vm._v("fa-solid fa-lock")]):_vm._e()]}}:null,{key:"list-title",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])]}},{key:"list-subtitle",fn:function(ref){
var item = ref.item;
return _vm._l((item.pathologies),function(pat){return _c('v-chip',{key:pat.id,staticClass:"mt-1 mr-2 hidden-md-and-down",attrs:{"small":"","label":""}},[_vm._v(_vm._s(pat.value))])})}},{key:"append-content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"list-study-detail-panel"},[_c('div',{staticClass:"list-study-detail-panel-institute hidden-md-and-down"},[_c('v-icon',[_vm._v("fa-solid fa-hospital")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(item.instituteClinicalStudies && item.instituteClinicalStudies.length ? item.instituteClinicalStudies.length : '-'))])],1),_c('div',{staticClass:"list-study-detail-panel-user"},[_c('v-icon',[_vm._v("fa-solid fa-users")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.config.service.getPatientNumber(item)))])],1),_c('div',{staticClass:"list-study-detail-panel-date hidden-md-and-down"},[_c('v-icon',[_vm._v("fa-solid fa-calendar")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(item.startDate ? _vm.dateUtil.fromISOStringToItaDate(item.startDate) : '-'))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(item.estimateEndDate ? _vm.dateUtil.fromISOStringToItaDate(item.estimateEndDate) : '-'))])],1)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }