<template>
    <page-detail :config="getConfig" :model.sync="taxonomy"></page-detail>
</template>

<script>
import PageDetail from "@/components/PageDetail";
import TaxonomyService from "@/service/TaxonomyService";

export default {
    name: "TaxonomyDetail",
    components: {PageDetail},
    data() {
        return {
            taxonomy: {}
        }
    },
    computed: {
        getConfig() {
            return {
                text: {
                    title: 'Taxonomy - Dettaglio',
                    name: 'Taxonomy'
                },
                service: new TaxonomyService(),
                fields: this.getFields()
            }
        }
    },
    methods: {
        getFields() {

            let fields = [
                {
                    label: 'Tipo',
                    key: 'type',
                    cols: 12
                },
                {
                    label: 'Chiave',
                    key: 'key',
                    cols: 12
                },
                {
                    label: 'Valore',
                    key: 'value',
                    cols: 12
                }
            ];

            if (this.configDialogObj.sortable) {
                fields.push({
                    label: 'Ordine',
                    key: 'order',
                    cols: 12
                });
            }

            return fields;
        }
    }
}
</script>

