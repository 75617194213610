<template>
    <v-stepper elevation="0">
        <v-stepper-header>
            <template v-for="step of getSteps()">
                <v-stepper-step editable :step="step.id" :key="'stepper-' + step.id" :rules="[validateStep(step.id)]">{{step.title}}</v-stepper-step>
                <v-divider :key="'divider-' + step.id"></v-divider>
            </template>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content v-for="step of getSteps()" :step="step.id" :key="step.id" class="notransition">
                <form-custom :ref="'formStep' + step.id" :fields="step.fields" hide-save="true" :model="model" :multi-field-x-row="config.multiFieldXRow" :errors="config.errors"></form-custom>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>

</template>

<script>
    import FormCustom from "./FormCustom";

    export default {
        name: "Stepper",
        components: {FormCustom},
        data: () => ({
            validated: false
        }),
        props: [
            'config', 'model'
        ],

        methods: {
            updateConfig(clinicalStudy) {
                console.log(clinicalStudy);
            },

            getFields(step) {
                return this.config.fields.filter(f => f.step && f.step === step);
            },

            getSteps() {
                return this.config.steps.filter(s => !s.hidden);
            },

            validate() {

                this.validated = true;

                let valid = true;
                for (const step of this.getSteps()) {
                    valid &= this.$refs['formStep' + step.id][0].validate();
                }
                return valid;
            },

            validateStep(id) {

                return () => {
                    if (!this.validated) {
                        return true;
                    }

                    if (this.$refs['formStep' + id]) {
                        return this.$refs['formStep' + id][0].validate();
                    }
                    return false;
                }
            }
        },

        created() {
            for (const step of this.config.steps) {
                step.fields = this.config.fields.filter(f => f.step && f.step === step.id);
            }
        }
    }
</script>
