<template>
    <v-container class="login-container" fluid fill-height>
        <v-layout>
            <v-flex>
                <v-img v-if="$root.$showLogo" class="login-logo" src="../assets/logo.svg" />
                <v-card class="elevation-12 pa-5">
                    <div class="text-h5 transition-swing"> <v-icon x-large>mdi-check-bold</v-icon> Richiesta inviata.</div>
                    <br>
                    <div class="text--lighten-2 transition-swing" v-text="'Riceverai una mail con le istruzioni .....'"></div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "RecoverPasswordConfirmed"
}
</script>

