<template>
    <page-form :config="config" :model.sync="institute" v-on:update:model="updateConfig" @saved="saved()"></page-form>
</template>

<script>

import PageForm from "@/components/PageForm";
import InstituteService from "@/service/InstituteService";
import TaxonomyService from "../../service/TaxonomyService";
import AuthService from "../../service/AuthService";
import ValidationUtil from "../../utils/ValidationUtil";
import UserService from "../../service/UserService";

const taxonomyService = new TaxonomyService();
const authService = new AuthService();
const userService = new UserService();
const validationUtil = new ValidationUtil();

export default {
    name: 'PharmaForm',
    components: {PageForm},
    data() {
        return {
            institute: {},
            config: {
                text: {
                    successMessage: 'Centro salvato',
                    titleNew: 'Centro - Nuovo',
                    titleEdit: 'Centro - Modifica',
                    name: 'Centro'
                },
                service: new InstituteService(),
                errors: {
                    name: '',
                    managerUsername: ''
                },
                multiFieldXRow: true,
                fields: [
                    {
                        name: 'FormField',
                        key: 'name',
                        cols: 6,
                        label: 'Nome',
                        required: true,
                        maxlength: 255,
                        customEvents: {
                            'blur': a => {
                                this.checkName(a.target.value);
                            },
                            'input': () => {
                                this.config.errors['name'] = '';
                            }
                        }
                    },
                    {
                        name: 'FormField',
                        key: 'address',
                        cols: 6,
                        maxlength: 255,
                        required: true,
                        label: 'Indirizzo'
                    },
                    {
                        name: 'FormSelect',
                        key: 'region',
                        cols: 6,
                        label: 'Regione',
                        items: [],
                        required: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        change: this.loadProvince
                    },
                    {
                        name: 'FormSelect',
                        key: 'province',
                        cols: 6,
                        label: 'Provincia',
                        items: [],
                        required: true,
                        itemValue: 'id',
                        disabled: true,
                        itemText: 'value',
                        returnObject: true,
                    },
                    {
                        name: 'FormCheckBox',
                        key: 'manager',
                        cols: 12,
                        label: 'Aggiungi admin centro',
                        change: (val) => {
                            this.config.fields.find(i => i.key === 'managerUsername').hidden = !val;
                            this.config.fields.find(i => i.key === 'managerFirstName').hidden = !val;
                            this.config.fields.find(i => i.key === 'managerLastName').hidden = !val;
                            this.config.fields.find(i => i.key === 'managerPhone').hidden = !val;
                            this.config.fields.find(i => i.key === 'managerTherapeuticAreas').hidden = !val;
                            this.config.fields.find(i => i.key === 'managerDepartment').hidden = !val;
                        },
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'managerFirstName',
                        cols: 6,
                        required: true,
                        label: 'Nome',
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'managerLastName',
                        cols: 6,
                        required: true,
                        label: 'Cognome',
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'managerUsername',
                        cols: 6,
                        required: true,
                        label: 'Mail',
                        customEvents: {
                            'blur': a => {
                                this.checkEmail(a.target.value);
                            },
                            'input': () => {
                                this.config.errors['managerUsername'] = '';
                            }
                        },
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'managerPhone',
                        cols: 6,
                        type: 'number',
                        label: 'Telefono',
                        hidden: true
                    },
                    {
                        name: 'FormSelect',
                        key: 'managerTherapeuticAreas',
                        cols: 6,
                        label: 'Aree terapeutiche',
                        items: [],
                        multiple: true,
                        itemValue: 'id',
                        itemText: 'value',
                        returnObject: true,
                        hidden: true
                    },
                    {
                        name: 'FormField',
                        key: 'managerDepartment',
                        maxlength: 150,
                        cols: 6,
                        placeholder: 'Reparto',
                        hidden: true
                    }
                ],
                url: '/institute'
            }
        };
    },

    methods: {

        checkEmail(email) {
            if (email) {

                if (!validationUtil.validateEmail(email)) {
                    this.config.errors['managerUsername'] = 'Email non valida';
                    return;
                }

                userService.checkEmail(undefined, email).then(() => {}, error => {
                    this.config.errors['managerUsername'] = error.response.data.message;
                });
            }
        },

        checkName(name) {
            if (name) {
                this.config.service.checkName(this.institute.id, name).then((result) => {
                    if (result) {
                        console.log(result);
                    }
                }, error => {
                    console.log(error.response.data);
                    this.config.errors['name'] = error.response.data.message;
                });
            }
        },

        loadProvince(region) {
            this.config.fields.find(i => i.key === 'province').disabled = true;
            taxonomyService.findAllChildrenFlat('REGION', region.key).then(res => {
                this.config.fields.find(i => i.key === 'province').items = res.data;
                this.config.fields.find(i => i.key === 'province').disabled = false;
            });
        },

        updateConfig(institute) {
            this.loadProvince(institute.region);

            if (institute.id) {
                this.config.fields.find(i => i.key === 'manager').hidden = true;
                this.config.fields.find(i => i.key === 'managerUsername').hidden = true;
                this.config.fields.find(i => i.key === 'managerFirstName').hidden = true;
                this.config.fields.find(i => i.key === 'managerLastName').hidden = true;
                this.config.fields.find(i => i.key === 'managerPhone').hidden = true;
                this.config.fields.find(i => i.key === 'managerTherapeuticAreas').hidden = true;
                this.config.fields.find(i => i.key === 'managerDepartment').hidden = true;
            } else {
                this.config.fields.find(i => i.key === 'manager').hidden = false;
            }
        },

        saved() {
            if (authService.hasAnyRole(['pharma', 'manager', 'user'])) {

                if (authService.isManager() && this.institute.id === authService.getAuthUser().institute) {
                    this.$router.push('/institute-owner/' + authService.getAuthUser().institute);
                    let authUser = authService.getAuthUser();
                    authUser.instituteName = this.institute.name;
                    authService.setAuthUser(authUser);
                } else {
                    this.$router.push('/network');
                }
                if (!this.institute.id) {
                    this.$root.$invitationSuccess.open('Centro Aggiunto').then(() => {});
                }
            }
        }
    },

    mounted() {

        taxonomyService.findAll('THERAPEUTIC_AREA').then(res => {
            this.config.fields.find(i => i.key === 'managerTherapeuticAreas').items = res.data;
        });

        taxonomyService.findAllFlat('REGION').then(res => {
            this.config.fields.find(i => i.key === 'region').items = res.data;
        });

        if (authService.isPharma()) {
            this.config.url = '/network';
            this.config.hideSuccessMessage = true;
        }

        if (!this.$router.currentRoute.params.id) {
            this.config.fields.find(i => i.key === 'manager').hidden = false;
        }
    }
}

</script>
