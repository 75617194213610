<template>

    <page-list-panel ref="pageListComponent" :config="config">
        <template v-slot:list-title="{item}">
            {{item.name}} - {{item.province.value}}
        </template>
        <template v-slot:list-subtitle="{item}">
            {{item.managerName}}
        </template>
        <template v-slot:list-append-action="{item}">
            <v-btn class="mx-2" color="primary" small @click="removeFromNetwork(item, $event)" title="Rimuovi da network">
                <v-icon small>fa-solid fa-xmark</v-icon>
            </v-btn>
        </template>
    </page-list-panel>

</template>

<script>

import NetworkService from "@/service/NetworkService";
import AuthService from "@/service/AuthService";
import PageListPanel from "../../components/PageListPanel";

const authService = new AuthService();
const networkService = new NetworkService();

export default {
    name: 'NetworkPanel',
    components: {PageListPanel},

    data: () => ({
        config: {
            hideDivider: true,
            isEditVisible: (institute) => {
                return networkService.userCanEdit(authService.getAuthUser(), institute);
            },
            isDeleteVisible: (institute) => {
                return networkService.userCanEdit(authService.getAuthUser(), institute);
            },
            text: {
                name: authService.getAuthUser().role === 'pharma' ? 'Centri Hub Attivati' : authService.getAuthUser().instituteName,
                entity: 'network',
                searchPlaceholder: 'Cerca',
                deleteSuccessMessage: 'Centro rimosso correttamente'
            },
            service: networkService,
            permissions: {
                create: 'nw.create',
                retrieve: 'nw.retrieve',
                update: 'nw.update',
                delete: 'nw.delete'
            }

        }
    }),

    methods: {
        removeFromNetwork(item, event) {

            event.preventDefault();

            this.$root.$removeFromNetwork.open('Rimuovi da network', 'Sei sicuro di voler rimuovere questo centro?').then(() => {
                this.config.service.removeFromNetwork(item.id).then(result => {
                    if (result) {
                        this.$refs.pageListComponent.load();
                        this.$root.showSuccess('Centro rimosso dal network');
                    }
                }, error => {
                    this.$root.showError(error.response.data.message, error);
                });
            });
        }
    }
}

</script>
