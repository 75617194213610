<template>
    <v-btn @click="back" class="back-to-list" color="primary" :title="title">
        <v-icon :left="!!text">fa-solid fa-arrow-left</v-icon>
        {{ text }}
    </v-btn>
</template>

<script>
export default {
    name: "BackToList",
    props: [
        'text',
        'title'
    ],
    methods: {
        back() {
            history.back();
        }
    }
}
</script>
