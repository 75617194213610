import moment from "moment";

const DATE_FORMAT_ITA = 'DD/MM/YYYY';
const DATETIME_FORMAT_ITA = 'DD/MM/YYYY HH:mm:ss';

export default class DateUtil {

    fromISOStringToItaDate(date) {
        return moment(moment(date, moment.ISO_8601)).format(DATE_FORMAT_ITA);
    }

    fromISOStringToItaDateTime(date) {
        return moment(moment(date, moment.ISO_8601)).format(DATETIME_FORMAT_ITA);
    }
}
