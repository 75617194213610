<template>
    <div>
        <page-detail-tab :config="config" :model.sync="clinicalStudy" v-on:update:model="updateConfig()">
            <template v-slot:append-header>
                <v-btn :to="'/clinical-study/edit/' + clinicalStudy.id" class="ml-5" color="primary" v-if="!loading && canEdit()">
                    <v-icon left>fa-solid fa-pencil</v-icon>
                    Modifica
                </v-btn>
                <v-btn class="ml-5" color="primary" @click="addInstitute()" v-if="!loading && canAddInstitute()">
                    <v-icon left>fa-solid fa-hospital</v-icon>
                    Aggiungi investigator
                </v-btn>
                <v-btn class="ml-5" color="primary" @click="sendProposal()" v-if="!loading && canSendProposal()">
                    <v-icon left>fa-solid fa-user-plus</v-icon>
                    Richiesta candidatura
                </v-btn>
            </template>
            <template v-slot:after-tab>
                <v-tab>
                    Pazienti
                </v-tab>
            </template>
            <template v-slot:after-tab-item>
                <v-tab-item class="notransition">
                    <div class="mt-10 pl-8 d-flex patient-present-number" v-if="clinicalStudy.owner || clinicalStudy.hub">
                        Situazione pazienti: {{clinicalStudy.patientAccepted}} / {{clinicalStudy.number}}
                    </div>
                    <div class="mt-10 pl-8 d-flex patient-present-number" v-if="!clinicalStudy.owner && !clinicalStudy.hub">
                        Situazione pazienti: {{clinicalStudy.number - clinicalStudy.patientAccepted}}
                    </div>
                    <div class="mt-10 pl-8 d-flex patient-present-number" v-if="canEdit()">
                        <v-text-field label="Pazienti già reclutati"
                                      v-model="clinicalStudy.numberRecruited"
                                      type="number"
                                      :required="true"
                                      :dense="true">
                        </v-text-field>
                        <v-btn @click="saveRecruited()" class="mx-3" small color="primary" :disabled="!clinicalStudy.numberRecruited">
                            <v-icon small>fa-solid fa-save</v-icon>
                        </v-btn>
                    </div>
                    <data-list class="pa-4 list-patient-study" :config="{items: patients}">
                        <template v-slot:title="{item}">
                            {{item.userFullName}}
                        </template>
                        <template v-slot:subtitle="{item}">
                            {{item.institute}}
                        </template>
                        <template v-slot:append-content="{item}">
                            <span class="ml-4 d-flex align-center list-patient-number mr-16">
                                <v-icon title="Pazienti candidati">fa-solid fa-users</v-icon>
                                <span v-if="item.status == null || item.number === item.accepted" class="ml-5 mr-7">{{item.number}}</span>
                                <span v-if="item.status != null && item.number !== item.accepted" class="ml-5 mr-7">{{item.accepted}} / {{item.number}}</span>
                                <v-tooltip top v-if="item.note">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon @click="copyNote(item.note)" v-bind="attrs" v-on="on">fa-solid fa-note-sticky</v-icon>
                                    </template>
                                    <span>{{item.note}}</span>
                                </v-tooltip>
                            </span>
                        </template>
                        <template v-slot:action="{item}">
                            <span class="d-flex align-center" v-if="item.status == null && !canEdit()">
                                In attesa di conferma
                            </span>
                            <span class="d-flex align-center" v-if="item.status == null && canEdit()">
                                <v-select class="ml-16" v-model="item.accepted" dense :items="getPazientSelectItems(item.number)"></v-select>
                                <v-btn class="ml-10" small color="success" @click="confirmPatient(item)">
                                    <v-icon>fa-solid fa-check</v-icon>
                                </v-btn>
                                <v-btn class="ml-4" small color="error" @click="denyPatient(item)">
                                    <v-icon>fa-solid fa-times</v-icon>
                                </v-btn>
                            </span>
                            <template v-if="item.status != null">
                                <v-icon v-if="item.status === 1" color="success">fa-solid fa-check</v-icon>
                                <v-icon v-if="item.status === 2" color="error">fa-solid fa-times</v-icon>
                            </template>
                        </template>
                    </data-list>
                </v-tab-item>
            </template>
        </page-detail-tab>
        <dialog-content :options="{btnType: 'primary', hideActions: true}" ref="addInstitute">
            <template v-slot:content>
                <form-custom ref="addInstituteForm" @save="invite()" :save-text="'Invita'" :fields="fieldsSearchInstitute"  :model="instituteStudy" :errors="errorsInstitute"></form-custom>
            </template>
        </dialog-content>
        <dialog-content :options="{btnType: 'primary', confirmText: 'Conferma'}" ref="confirmPatient"></dialog-content>
        <dialog-content :options="{btnType: 'primary', confirmText: 'Rifiuta'}" ref="denyPatient"></dialog-content>
        <dialog-content :options="{btnType: 'primary', hideActions: true}" ref="sendProposal">
            <template v-slot:content>
                <form-custom ref="sendProposalForm" @save="send()" :save-text="'Invia'" :fields="fieldsSendProposal"  :model="proposal" :errors="errorsProposal"></form-custom>
            </template>
        </dialog-content>
        <dialog-content :options="{btnType: 'primary', confirmText: 'Chiudi', hideCancel: true}" ref="invitationStudySuccess">
            <template v-slot:content>
                <span>Il referente del centro ha ricevuto un messaggio di posta elettronica con l'invito ....<br></span>
            </template>
        </dialog-content>
        <dialog-content :options="{btnType: 'primary', confirmText: 'Chiudi', hideCancel: true}" ref="sendProposalSuccess">
            <template v-slot:content>
                <span>Il referente dello studio ha ricevuto un messaggio di posta elettronica con la proposta, dopo la conferma, riceverà una mail con  ....<br></span>
            </template>
        </dialog-content>
    </div>
</template>

<script>

import ClinicalStudyService from "../../service/ClinicalStudyService";
import PageDetailTab from "../../components/PageDetailTab";
import DialogContent from "../../components/DialogContent";
import FormCustom from "../../components/FormCustom";
import NetworkService from "@/service/NetworkService";
import RequestUtil from "../../utils/RequestUtil";
import UserService from "../../service/UserService";
import AuthService from "../../service/AuthService";
import DataList from "../../components/DataList";

const requestUtil = new RequestUtil();
const networkService = new NetworkService();
const userService = new UserService();
const authService = new AuthService();

export default {
    name: 'ClinicalStudyDetail',
    components: {PageDetailTab, DialogContent, FormCustom, DataList},
    data() {
        return {
            loading: true,
            authService,
            clinicalStudy: {
                institutes: []
            },
            instituteStudy: {},
            errorsInstitute: {},
            proposal: {},
            errorsProposal: {},
            patients: [],
            config: {
                tabs: [
                    {
                        id: 1,
                        icon: '',
                        title: 'Dati studio'
                    },
                    {
                        id: 2,
                        icon: '',
                        title: 'Ulteriori informazioni'
                    },
                    {
                        id: 3,
                        icon: '',
                        title: 'Criteri di inclusione'
                    }
                ],
                text: {
                    title: 'Studio - Dettaglio',
                    name: 'Studio'
                },
                service: new ClinicalStudyService(),
                fields: [
                    {
                        label: 'Titolo',
                        key: 'name',
                        cols: 12,
                        tab: 1
                    },
                    {
                        label: 'Descrizione',
                        key: 'description',
                        cols: 12,
                        tab: 1
                    },
                    {
                        label: 'Stato',
                        key: 'status.value',
                        cols: 12,
                        tab: 1
                    },
                    {
                        label: 'Principal Investigator',
                        key: 'principals',
                        cols: 12,
                        isCloseVisible: (item) => {
                            return (authService.getAuthUser().pharma != null && authService.getAuthUser().pharma === item.pharmaOwner?.id) || (authService.getAuthUser().institute != null && authService.getAuthUser().institute === item.instituteOwner?.id);
                        },
                        onClose: (id) => {
                            this.removeInstitute(id);
                        },
                        array: true,
                        tab: 1
                    },
                    {
                        label: 'Investigator',
                        key: 'institutesNotPrincipal',
                        isCloseVisible: (item) => {
                            return (authService.getAuthUser().pharma != null && authService.getAuthUser().pharma === item.pharmaOwner?.id) || (authService.getAuthUser().institute != null && authService.getAuthUser().institute === item.instituteOwner?.id);
                        },
                        onClose: (id) => {
                            this.removeInstitute(id);
                        },
                        cols: 12,
                        array: true,
                        tab: 1
                    },
                    {
                        key: 'startDate',
                        cols: 4,
                        label: 'Data Inizio',
                        tab: 2
                    },
                    {
                        key: 'estimateEndDate',
                        cols: 4,
                        label: 'Data Presunta Fine',
                        tab: 2
                    },
                    {
                        key: 'endDate',
                        cols: 4,
                        label: 'Data Fine',
                        tab: 2
                    },
                    {
                        key: 'type.value',
                        cols: 6,
                        label: 'Tipo di studio',
                        tab: 2,
                        text: 'Descrive la natura di uno studio clinico. I tipi di studio includono studi ....'
                    },
                    {
                        key: 'phase.value',
                        cols: 6,
                        label: 'Fase',
                        tab: 2
                    },
                    {
                        key: 'number',
                        cols: 6,
                        label: 'N° Pazienti richiesti',
                        tab: 2,
                    },
                    {
                        key: 'therapeuticAreas',
                        cols: 6,
                        label: 'Aree terapeutiche',
                        array: true,
                        tab: 2
                    },
                    {
                        key: 'treatment',
                        cols: 12,
                        label: 'Trattamento',
                        tab: 2
                    },
                    {
                        key: 'primaryTargets',
                        label: 'Obiettivi primari',
                        cols: 6,
                        array: true,
                        tab: 2
                    },
                    {
                        key: 'secondaryTargets',
                        cols: 6,
                        label: 'Obiettivi secondari',
                        array: true,
                        tab: 2
                    },
                    {
                        key: 'sex.value',
                        cols: 6,
                        label: 'Sesso',
                        tab: 3
                    },
                    {
                        key: 'ages',
                        cols: 6,
                        label: 'Età',
                        array: true,
                        tab: 3
                    },
                    {
                        key: 'criteria',
                        cols: 6,
                        label: 'Criteri di inclusione',
                        array: true,
                        tab: 3
                    },
                    {
                        key: 'protocol',
                        cols: 6,
                        label: 'Protocollo',
                        tab: 3
                    },
                    {
                        key: 'pathologies',
                        cols: 6,
                        label: 'Patologia',
                        array: true,
                        tab: 3
                    },
                    {
                        key: 'pathologiesText',
                        cols: 6,
                        label: 'Patologia ulteriori dettagli',
                        array: true,
                        tab: 3
                    }
                ]
            },
            fieldsSearchInstitute: [
                {
                    name: 'FormServerAutoComplete',
                    key: 'institute',
                    cols: 12,
                    items: [],
                    label: 'Centro',
                    itemText: 'name',
                    itemValue: 'id',
                    required: true,
                    returnObject: true,
                    search: (val) => {
                        let params = requestUtil.buildParams(val, { page: 1, itemsPerPage: 99999, sortBy: ['name'], sortDesc: []});
                        params.study = this.clinicalStudy.id;
                        networkService.searchForStudyInvitation(params).then(result => {
                            this.fieldsSearchInstitute.find(i => i.key === 'institute').items = result.data.data;
                        });
                    },
                    change: (institute) => {
                        let params = requestUtil.buildParams(null, { page: 1, itemsPerPage: 99999, sortBy: ['name'], sortDesc: []});
                        params.institute = institute.id;
                        userService.search(params).then((result) => {
                            this.fieldsSearchInstitute.find(i => i.key === 'user').items = result.data.data.map(userService.mapForView);
                        });
                    }
                },
                {
                    name: 'FormSelect',
                    key: 'user',
                    cols: 12,
                    required: true,
                    label: 'Medico',
                    items: [],
                    itemValue: 'id',
                    itemText: 'fullNameAndTherapeuticAreasText'
                },
                {
                    name: 'FormCheckBox',
                    key: 'principal',
                    cols: 12,
                    label: 'Principal investigator'
                },
            ],
            fieldsSendProposal: [
                {
                    name: 'FormField',
                    key: 'number',
                    cols: 12,
                    label: 'Numero pazienti',
                    type: 'number',
                    required: true,
                },
                {
                    name: 'FormTextArea',
                    key: 'note',
                    cols: 12,
                    label: 'Note',
                    numberRows: 3
                }
            ]
        }
    },
    methods: {

        getPazientSelectItems(number) {
            let items = [];
            for (let i = 1; i <= number; i++) {
                items.push(i);
            }
            return items;
        },

        addInstitute() {
            this.instituteStudy = {};
            this.errorsInstitute = {};
            this.instituteStudy.clinicalStudy = this.clinicalStudy.id;
            if (this.$refs.addInstituteForm) {
                this.$refs.addInstituteForm.resetValidation();
            }
            this.$refs.addInstitute.open('Aggiungi Investigator').then(() => {});
        },

        confirmPatient(patient) {
            this.$refs.confirmPatient.open('Conferma Candidatura', 'Confermi la candidatura proposta').then(() => {
                this.config.service.confirmPatient(this.clinicalStudy.id, patient).then(() => {
                    this.$root.showSuccess('Candidatura accettata');
                    this.loadPatients();
                    this.reload();
                }, error => {
                    this.$root.showError(error.response.data.message, error);
                });
            });
        },

        denyPatient(patient) {
            this.$refs.denyPatient.open('Rifiuta Candidatura', 'Rifiuti la candidatura proposta').then(() => {
                this.config.service.denyPatient(this.clinicalStudy.id, patient).then(() => {
                    this.$root.showSuccess('Candidatura rifiutata');
                    this.loadPatients();
                    this.reload();
                }, error => {
                    this.$root.showError(error.response.data.message, error);
                });
            });
        },

        sendProposal() {
            this.proposal = {};
            this.errorsProposal = {};
            this.proposal.clinicalStudy = this.clinicalStudy.id;
            if (this.$refs.sendProposalForm) {
                this.$refs.sendProposalForm.resetValidation();
            }
            this.$refs.sendProposal.open('Invia una candidatura allo studio').then(() => {});
        },

        send() {
            this.config.service.sendProposal(this.proposal).then(() => {
                this.$refs.sendProposal.hide();
                this.reload();
                this.$refs.sendProposalSuccess.open('Candidatura inviata').then(() => {});
            }, error => {
                this.$refs.sendProposal.hide();
                this.$root.showError(error.response.data.message, error);
            });
        },

        invite() {
            networkService.inviteOnStudy(this.instituteStudy).then(() => {
                this.$refs.addInstitute.hide();
                this.reload();
                this.$refs.invitationStudySuccess.open('Investigator Aggiunto').then(() => {});
            }, error => {
                this.$root.showError(error.response.data.message, error);
            });
        },

        updateConfig() {
            this.clinicalStudy.principals = this.clinicalStudy.institutes.filter(ic => ic.principal);
            this.clinicalStudy.institutesNotPrincipal = this.clinicalStudy.institutes.filter(ic => !ic.principal);
            this.loadPatients();
            this.loading = false;
        },

        removeInstitute(id) {
            this.$root.$confirm.open('Elimina elemento', 'Sei sicuro di voler eliminare questo centro?').then(() => {
                this.config.service.remove(this.clinicalStudy.id, id).then(() => {
                    this.$root.showSuccess('Centro rimosso correttamente');
                    this.reload();
                }, error => {
                    this.$root.showError(error.response.data.message, error);
                });
            });
        },

        reload() {
            this.config.service.find(this.$route.params.id).then((result) => {
                this.clinicalStudy = this.config.service.mapForView(result.data);
                this.updateConfig();
            });
        },

        canEdit() {
            return this.config.service.userCanEdit(authService.getAuthUser().id, this.clinicalStudy);
        },

        canAddInstitute() {
            return this.config.service.userCanEdit(authService.getAuthUser().id, this.clinicalStudy);
        },

        canSendProposal() {

            if (authService.getAuthUser().role === 'pharma') {
                return false;
            }

            return !this.clinicalStudy.owner && !this.clinicalStudy.spoke && !this.clinicalStudy.hub;
        },

        loadPatients() {
            this.config.service.patients(this.clinicalStudy.id).then((res) => {
                this.patients = res.data;
            });
        },

        saveRecruited() {
            this.config.service.saveRecruited(this.clinicalStudy).then(() => {
                this.$root.showSuccess('Numero pazienti salvato');
                this.reload();
            }, error => {
                this.$root.showError(error.response.data.message, error);
            });
        },

        copyNote(note) {
            navigator.clipboard.writeText(note).then(() => {
                this.$root.showSuccess('Note copiate');
            });
        }
    }
}

</script>
